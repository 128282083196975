/* eslint-disable */

function _extends() { return (_extends = Object.assign || function (e) { for (var n = 1; n < arguments.length; n++) { var t = arguments[n]; for (var r in t)Object.prototype.hasOwnProperty.call(t, r) && (e[r] = t[r]); } return e; }).apply(this, arguments); } function Alignment(e) {
    var n = e.viewSize,
        t = e.align,
        r = { start: function () { return 0; }, center: function (e) { return (n - e) / 2; }, end: function (e) { return n - e; } }; return { measure: function (e) { return typeof t == 'number' ? n * Number(t) : r[t](e); } };
} function Animation(e) {
    var n = requestAnimationFrame.bind(window),
        t = cancelAnimationFrame.bind(window),
        r = 0; function o(e, n) { return function () { e === !!r && n(); }; } function i() { r = n(e); } return { proceed: o(!0, i), start: o(!1, i), stop: o(!0, function () { t(r), r = 0; }) };
} function Axis(e) {
    var n = e === 'y' ? 'y' : 'x'; return { cross: e === 'y' ? 'x' : 'y',
        measure: function (e) {
            var t = e.getBoundingClientRect(),
                r = t.width,
                o = t.height; return n === 'x' ? r : o;
        },
        scroll: n };
} function Counter(e) {
    var n = e.start,
        t = e.limit,
        r = e.loop,
        o = t.min,
        i = t.max,
        a = r ? 'loop' : 'constrain',
        c = l(n); function u() { return c; } function s(e) { return c = l(e), d; } function l(e) { return t[a](e); } var d = { add: function e(n) { if (n !== 0) { var t = n / Math.abs(n); return s(u() + t), e(n + -1 * t); } return d; }, clone: function () { return Counter({ start: u(), limit: t, loop: r }); }, get: u, max: i, min: o, set: s }; return d;
} function Vector1D(e) { var n = e; function t(e) { return n /= e, o; } function r(e) { return typeof e == 'number' ? e : e.get(); } var o = { add: function (e) { return n += r(e), o; }, divide: t, get: function () { return n; }, multiply: function (e) { return n *= e, o; }, normalize: function () { return n !== 0 && t(n), o; }, set: function (e) { return n = r(e), o; }, subtract: function (e) { return n -= r(e), o; } }; return o; } function Direction(e) { var n = Vector1D(t(e)); function t(e) { return e === 0 ? 0 : e / Math.abs(e); } var r = { get: n.get, set: function (e) { var o = t(e.get()); return o !== 0 && n.set(o), r; } }; return r; } function EventStore() { var e = []; var n = { add: function (t, r, o, i) { return void 0 === i && (i = !1), t.addEventListener(r, o, i), e.push(function () { return t.removeEventListener(r, o, i); }), n; }, removeAll: function () { return e.filter(function (e) { return e(); }), e.length = 0, n; } }; return n; } function DragHandler(e) {
    var n = e.target,
        t = e.scrollBody,
        r = e.dragFree,
        o = e.animation,
        i = e.axis,
        a = e.element,
        c = e.dragTracker,
        u = e.location,
        s = e.events,
        l = e.limit,
        d = i.scroll,
        f = i.cross,
        m = ['INPUT', 'SELECT', 'TEXTAREA'],
        v = Vector1D(0),
        p = Vector1D(0),
        g = Vector1D(0),
        x = EventStore(),
        S = EventStore(),
        h = x.removeAll,
        y = S.removeAll,
        w = { mouse: 2.5, touch: 3.5 },
        D = { mouse: 4, touch: 7 },
        T = { mouse: 12, touch: 14 },
        b = { mouse: 6, touch: 5 },
        E = 4,
        M = !1,
        z = !1,
        C = !1,
        A = !1; function P(e) {
        if (!(A = e.type === 'mousedown') || e.button === 0) {
            var r,
                o,
                i = I(n.get(), u.get()) >= 2,
                l = A || !i,
                x = (r = e.target, o = r.nodeName || '', !(m.indexOf(o) > -1)),
                h = i || A && x; M = !0, c.pointerDown(e), g.set(n), n.set(u), t.useDefaultMass().useSpeed(80), (function () { var e = A ? document : a; S.add(e, 'touchmove', L).add(e, 'touchend', V).add(e, 'mousemove', L).add(e, 'mouseup', V); }()), v.set(c.readPoint(e, d)), p.set(c.readPoint(e, f)), s.emit('pointerDown'), l && (C = !1), h && e.preventDefault();
        }
    } function L(t) {
        if (!z && !A) {
            var r = c.readPoint(t, d).get(),
                i = c.readPoint(t, f).get(),
                a = I(r, v.get()),
                s = I(i, p.get()); if (!(z = a > s) && !C) return V();
        } var m = c.pointerMove(t),
            g = l.reachedAny(u.get()),
            x = !e.loop && g ? 2 : 1; !C && m && (C = !0), o.start(), n.add(m / x), t.preventDefault();
    } function V() {
        var o = c.pointerUp() * (r ? D : w)[A ? 'mouse' : 'touch']; I(n.get(), g.get()) >= 0.5 && !A && (C = !0), A = !1, z = !1, M = !1, S.removeAll(), t.useSpeed((r ? b : T)[A ? 'mouse' : 'touch']), (function (t) {
            var o = e.scrollTo,
                i = e.scrollTarget,
                a = e.index,
                c = l.reachedAny(n.get() + t),
                u = !(i.byDistance(0, !1).index !== a.get()) && Math.abs(t) > E; if (r || c || !u)o.distance(t, !r); else {
                var s = -1 * Direction(t).get(),
                    d = a.clone().add(s); o.index(d.get(), 0);
            }
        }(o)), s.emit('pointerUp');
    } function I(e, n) { return Math.abs(e - n); } function B(e) { C && e.preventDefault(); } return { addActivationEvents: function () { var e = a; x.add(e, 'touchmove', function () {}).add(e, 'touchend', function () {}).add(e, 'touchstart', P).add(e, 'mousedown', P).add(e, 'touchcancel', V).add(e, 'contextmenu', V).add(e, 'click', B); }, clickAllowed: function () { return !C; }, pointerDown: function () { return M; }, removeActivationEvents: h, removeInteractionEvents: y };
} function DragTracker(e) {
    var n = e.axis,
        t = e.pxToPercent,
        r = n.scroll,
        o = { x: 'clientX', y: 'clientY' },
        i = Vector1D(0),
        a = Vector1D(0),
        c = Vector1D(0),
        u = Vector1D(0),
        s = 10,
        l = [],
        d = (new Date()).getTime(),
        f = !1; function m(e, n) {
        f = !e.touches; var t = o[n],
            r = f ? e[t] : e.touches[0][t]; return u.set(r);
    } return { pointerDown: function (e) { var n = m(e, r); return i.set(n), c.set(n), t.measure(i.get()); },
        pointerMove: function (e) {
            var n = m(e, r),
                o = (new Date()).getTime(); return o - d >= s && (l.push(n.get()), d = o), a.set(n).subtract(c), c.set(n), t.measure(a.get());
        },
        pointerUp: function () {
            var e = c.get(),
                n = f ? 5 : 4,
                r = l.slice(-n).map(function (n) { return e - n; }).sort(function (e, n) { return Math.abs(e) < Math.abs(n) ? 1 : -1; })[0]; return c.set(r || 0), l = [], t.measure(c.get());
        },
        readPoint: m };
} function Limit(e) {
    var n = e.min,
        t = e.max,
        r = { min: t, max: n },
        o = { min: n, max: t },
        i = Math.abs(n - t); function a(e) { return e < n; } function c(e) { return e > t; } function u(e) { return a(e) ? 'min' : c(e) ? 'max' : ''; } return { constrain: function (e) { var n = u(e); return n ? o[n] : e; }, length: i, loop: function (e) { var n = u(e); return n ? r[n] : e; }, max: t, min: n, reachedAny: function (e) { return a(e) || c(e); }, reachedMax: c, reachedMin: a, removeOffset: function (e) { if (n === t) return e; for (;a(e);)e += i; for (;c(e);)e -= i; return e; } };
} function PxToPercent(e) { var n = 100; var t = { measure: function (t) { return t / e * n; }, totalPercent: n }; return Object.freeze(t); } function map(e, n, t, r, o) { return r + (e - n) / (t - n) * (o - r); } function arrayFromCollection(e) { return Array.prototype.slice.call(e); } function debounce(e, n) { var t = { id: 0 }; return function () { window.clearTimeout(t.id), t.id = window.setTimeout(e, n) || 0; }; } function roundToDecimals(e) { var n = Math.pow(10, e); return function (e) { return Math.round(e * n) / n; }; } function groupArray(e, n) { for (var t = [], r = 0; r < e.length; r += n)t.push(e.slice(r, r + n)); return t; } function arrayKeys(e) { return Object.keys(e).map(Number); } function removeClass(e, n) { var t = e.classList; t.contains(n) && t.remove(n); } function addClass(e, n) { var t = e.classList; t.contains(n) || t.add(n); } function ScrollBody(e) {
    var n = e.location,
        t = e.speed,
        r = e.mass,
        o = roundToDecimals(2),
        i = Vector1D(0),
        a = Vector1D(0),
        c = Vector1D(0),
        u = Direction(0),
        s = { speed: t, mass: r }; function l(e) { return s.speed = e, f; } function d(e) { return s.mass = e, f; } var f = { direction: u,
        location: n,
        seek: function (e) { c.set(e).subtract(n); var t = map(c.get(), 0, 100, 0, s.speed); return u.set(c), c.normalize().multiply(t).subtract(i), (function (e) { e.divide(s.mass), a.add(e); }(c)), f; },
        settle: function (e) {
            var t = e.get() - n.get(),
                r = !o(t); return r && n.set(e), r;
        },
        update: function () { i.add(a), n.add(i), a.multiply(0); },
        useDefaultMass: function () { return d(r), f; },
        useDefaultSpeed: function () { return l(t), f; },
        useMass: d,
        useSpeed: l }; return f;
} function ScrollBounds(e) {
    var n = e.limit,
        t = e.location,
        r = e.scrollBody,
        o = e.animation,
        i = n.min,
        a = n.max,
        c = n.reachedMin,
        u = n.reachedMax,
        s = 50,
        l = !1,
        d = 0; return { constrain: function (e) { (function (e) { return !l && !d && (c(t.get()) ? e.get() !== i : !!u(t.get()) && e.get() !== a); }(e)) && (d = window.setTimeout(function () { var t = n.constrain(e.get()); e.set(t), r.useSpeed(10).useMass(3), o.start(), d = 0; }, s)); }, toggleActive: function (e) { l = !e; } };
} function ScrollContain(e) {
    var n = e.alignment,
        t = e.contentSize,
        r = e.viewSize,
        o = Limit({ min: -t + r, max: 0 }),
        i = [n.measure(t)],
        a = t > r; return { measure: function (e, n) {
        var t = e.map(o.constrain),
            r = (function (e) {
                var n = e[0],
                    t = e[e.length - 1]; return Limit({ min: e.lastIndexOf(n) + 1, max: e.indexOf(t) });
            }(t)),
            c = r.min,
            u = r.max; return a ? n ? t.slice(c - 1, u + 1) : t : i;
    } };
} function ScrollLimit(e) {
    var n = e.contentSize,
        t = e.loop; return { measure: function (e) {
        var r = e[0],
            o = e[e.length - 1]; return Limit({ min: t ? r - n : o, max: r });
    } };
} function ScrollLooper(e) {
    var n = e.contentSize,
        t = e.location,
        r = e.limit,
        o = e.pxToPercent,
        i = Limit({ min: r.min + o.measure(0.1), max: r.max + o.measure(0.1) }),
        a = i.reachedMin,
        c = i.reachedMax; return { loop: function (e, r) { if (function (e) { return e === 1 ? c(t.get()) : e === -1 && a(t.get()); }(r)) { var o = n * (-1 * r); e.forEach(function (e) { return e.add(o); }); } } };
} function ScrollProgress(e) {
    var n = e.limit,
        t = n.max,
        r = n.length; return { get: function (e) { return (e - t) / -r; } };
} function ScrollSnap(e) {
    var n,
        t = e.snapSizes,
        r = e.alignment,
        o = e.loop,
        i = t.map(r.measure),
        a = (n = Counter({ limit: Limit({ min: 0, max: t.length - 1 }), start: 0, loop: o }), t.map(function (e, t) { var r = n.set(t + 1).get(); return e + i[t] - i[r]; })); return { measure: function (e) { return a.slice(0, e).reduce(function (e, n) { return e - n; }, i[0]); } };
} function ScrollTarget(e) {
    var n = e.loop,
        t = e.limit,
        r = e.scrollSnaps,
        o = e.contentSize,
        i = t.reachedMax,
        a = t.reachedAny,
        c = t.removeOffset; function u(e, n) { return Math.abs(e) < Math.abs(n) ? e : n; } function s(e, t) {
        var r = e,
            i = e + o,
            a = e - o; if (!n) return r; if (!t) return u(u(r, i), a); var c = u(r, t === 1 ? i : a); return Math.abs(c) * t;
    } return { byDistance: function (t, o) {
        var u = e.target.get() + t,
            l = (function (e) { var n = c(e); return { index: r.map(function (e) { return e - n; }).map(function (e) { return s(e, 0); }).map(function (e, n) { return { diff: e, index: n }; }).sort(function (e, n) { return Math.abs(e.diff) - Math.abs(n.diff); })[0].index, distance: n }; }(u)),
            d = (function (t, r) {
                if (n || !a(t)) return r; var o = e.index,
                    c = o.min,
                    u = o.max; return i(t) ? c : u;
            }(u, l.index)),
            f = !n && a(u); return !o || f ? { index: d, distance: t } : { index: d, distance: t + s(r[d] - l.distance, 0) };
    },
    byIndex: function (n, t) { return { index: n, distance: s(r[n] - e.target.get(), t) }; },
    shortcut: s };
} function ScrollTo(e) {
    var n = e.index,
        t = e.scrollTarget,
        r = e.animation,
        o = e.indexPrevious,
        i = e.events,
        a = e.target; function c(e) {
        var t = e.distance,
            c = e.index !== n.get(); t && (r.start(), a.add(t)), c && (o.set(n.get()), n.set(e.index), i.emit('select'));
    } return { distance: function (e, n) { c(t.byDistance(e, n)); }, index: function (e, r) { var o = n.clone().set(e); c(t.byIndex(o.get(), r)); } };
} function SlideLooper(e) {
    var n,
        t,
        r,
        o = e.axis,
        i = e.location,
        a = e.contentSize,
        c = e.viewSize,
        u = e.slideSizes,
        s = e.scrollSnaps,
        l = arrayKeys(u),
        d = arrayKeys(u).reverse(),
        f = (n = s[0] - 1, t = p(n, d), r = g(n, t, 0), x(t, r, 1)).concat(function () {
            var e = p(c - s[0] - 1, l),
                n = g(a, l, -c); return x(e, -n, 0);
        }()),
        m = o.scroll === 'x' ? 'left' : 'top'; function v(e, n) { return e.reduce(function (e, n) { return e - u[n]; }, n); } function p(e, n) { return n.reduce(function (n, t) { return v(n, e) > 0 ? n.concat([t]) : n; }, []); } function g(e, n, t) { return n.reduce(function (n, t) { var r = n + u[t]; return r < e ? r : n; }, t); } function x(e, n, t) {
        var r = e.slice().sort(function (e, n) { return e - n; }); return r.map(function (e, o) {
            var i = a * (t ? -1 : 0),
                c = a * (t ? 0 : 1),
                u = (function (e, n, t) { var r = l.length - 1; return v(e.map(function (e) { return (e + t) % r; }), n); }(r.slice(0, o), n, t)); return { point: u, getTarget: function (e) { return e > u ? i : c; }, index: e, location: -1 };
        });
    } return { canLoop: function () { return f.every(function (e) { var n = e.index; return v(l.filter(function (e) { return e !== n; }), c) <= 0; }); },
        clear: function (e) { f.forEach(function (n) { var t = n.index; e[t].style[m] = ''; }); },
        loop: function (e) {
            f.forEach(function (n) {
                var t = n.getTarget,
                    r = n.location,
                    o = n.index,
                    a = t(i.get()); a !== r && (e[o].style[m] = a + '%', n.location = a);
            });
        },
        loopPoints: f };
} function SlidesInView(e) {
    var n = e.contentSize,
        t = e.slideSizes,
        r = e.viewSize,
        o = e.inViewThreshold,
        i = e.loop,
        a = t.map(function (e) { return e * o; }),
        c = arrayKeys(t).map(function (e) { return t.slice(0, e).reduce(function (e, n) { return e - n; }, 0); }),
        u = (i ? [0, n, -n] : [0]).map(s).reduce(function (e, n) { return e.concat(n); }, []); function s(e) { return c.map(function (n, o) { return { start: n - t[o] + a[o] + e, end: n + r - a[o] + e, index: o }; }); } return { check: function (e) {
        return u.reduce(function (n, t) {
            var r = t.index,
                o = t.start,
                i = t.end; return n.indexOf(r) === -1 && o < e && i > e ? n.concat([r]) : n;
        }, []);
    } };
} function Translate(e) {
    var n = e.axis,
        t = e.container,
        r = { x: function (e) { return 'translate3d(' + e + '%,0px,0px)'; }, y: function (e) { return 'translate3d(0px,' + e + '%,0px)'; } }[n.scroll],
        o = roundToDecimals(2),
        i = t.style,
        a = !1,
        c = 0; return { clear: function () { i.transform = '', c = 0; }, to: function (e) { if (!a) { var n = o(e.get()); c !== n && (getComputedStyle(t).transform, i.transform = r(n), c = n); } }, toggleActive: function (e) { a = !e; } };
} function Engine(e, n, t, r, o) {
    var i = r.align,
        a = r.axis,
        c = r.startIndex,
        u = r.inViewThreshold,
        s = r.loop,
        l = r.speed,
        d = r.dragFree,
        f = r.slidesToScroll,
        m = r.containScroll,
        v = Axis(a),
        p = PxToPercent(v.measure(n)),
        g = p.totalPercent,
        x = t.map(v.measure).map(p.measure),
        S = groupArray(x, f).map(function (e) { return e.reduce(function (e, n) { return e + n; }); }),
        h = arrayKeys(S),
        y = x.reduce(function (e, n) { return e + n; }, 0),
        w = Alignment({ align: i, viewSize: g }),
        D = ScrollSnap({ snapSizes: S, alignment: w, loop: s }),
        T = h.map(D.measure),
        b = ScrollContain({ alignment: w, contentSize: y, viewSize: g }),
        E = !s && m !== '',
        M = m === 'trimSnaps',
        z = b.measure(T, M),
        C = E ? z : T,
        A = Counter({ limit: Limit({ min: 0, max: C.length - 1 }), start: c, loop: s }),
        P = A.clone(),
        L = ScrollLimit({ loop: s, contentSize: y }).measure(C),
        V = Animation(function () { K.scrollBody.seek(O).update(); var e = K.scrollBody.settle(O); if (H.pointerDown() || (s || K.scrollBounds.constrain(O), e && (K.animation.stop(), o.emit('settle'))), s) { var n = K.scrollBody.direction.get(); K.scrollLooper.loop(k, n), K.slideLooper.loop(t); }e || o.emit('scroll'), K.translate.to(K.scrollBody.location), K.animation.proceed(); }),
        I = C[A.get()],
        B = Vector1D(I),
        O = Vector1D(I),
        k = [B, O],
        N = ScrollBody({ location: B, speed: l, mass: 1 }),
        U = ScrollTarget({ contentSize: y, index: A, limit: L, loop: s, scrollSnaps: C, target: O }),
        F = ScrollTo({ animation: V, events: o, index: A, indexPrevious: P, scrollTarget: U, target: O }),
        H = DragHandler({ animation: V, axis: v, dragFree: d, dragTracker: DragTracker({ axis: v, pxToPercent: p }), element: e, events: o, index: A, limit: L, location: B, loop: s, scrollBody: N, scrollTo: F, scrollTarget: U, target: O }),
        K = { animation: V, axis: v, dragHandler: H, pxToPercent: p, index: A, indexPrevious: P, limit: L, location: B, options: r, scrollBody: N, scrollBounds: ScrollBounds({ animation: V, limit: L, location: B, scrollBody: N }), scrollLooper: ScrollLooper({ contentSize: y, limit: L, location: B, pxToPercent: p }), scrollProgress: ScrollProgress({ limit: L }), scrollSnaps: C, scrollTarget: U, scrollTo: F, slideLooper: SlideLooper({ axis: v, contentSize: y, location: B, scrollSnaps: C, slideSizes: x, viewSize: g }), slidesInView: SlidesInView({ contentSize: y, inViewThreshold: u, loop: s, slideSizes: x, viewSize: g }), snapIndexes: h, target: O, translate: Translate({ axis: v, container: n }) }; return K;
} function EventEmitter() { var e = { destroy: [], pointerDown: [], pointerUp: [], init: [], reInit: [], resize: [], scroll: [], select: [], settle: [] }; var n = { emit: function (t) { return e[t].forEach(function (e) { return e(t); }), n; }, off: function (t, r) { return e[t] = e[t].filter(function (e) { return e !== r; }), n; }, on: function (t, r) { return e[t] = e[t].concat([r]), n; } }; return n; } var defaultOptions = { align: 'center', axis: 'x', containScroll: '', containerSelector: '*', dragFree: !1, draggable: !0, draggableClass: 'is-draggable', draggingClass: 'is-dragging', inViewThreshold: 0, loop: !1, selectedClass: 'is-selected', slidesToScroll: 1, speed: 10, startIndex: 0 }; function EmblaCarousel(e, n) {
    void 0 === n && (n = {}); var t,
        r,
        o,
        i = EventEmitter(),
        a = EventStore(),
        c = debounce(function () { var e = t.axis.measure(r); m !== e && S(); i.emit('resize'); }, 500),
        u = S,
        s = i.on,
        l = i.off,
        d = !1,
        f = _extends({}, defaultOptions),
        m = 0; function v(n) {
        void 0 === n && (n = {}), (function () {
            if (!e) throw new Error('Missing root node 😢'); var n = f.containerSelector,
                t = e.querySelector(n); if (!t) throw new Error('Missing container node 😢'); o = arrayFromCollection((r = t).children);
        }()), f = _extends(f, n); var u = (t = Engine(e, r, o, f, i)).axis,
            s = t.scrollBody,
            l = t.translate,
            v = t.dragHandler,
            h = t.slideLooper,
            y = f.loop,
            w = f.draggable,
            D = f.draggableClass,
            T = f.selectedClass,
            b = f.draggingClass; if (m = u.measure(r), a.add(window, 'resize', c), l.to(s.location), o.forEach(x), v.addActivationEvents(), y) { if (!h.canLoop()) return S({ loop: !1 }); h.loop(o); }w ? (D && addClass(e, D), b && (i.on('pointerDown', p), i.on('pointerUp', p))) : i.on('pointerDown', v.removeInteractionEvents), T && (g(), i.on('select', g), i.on('pointerUp', g)), d || (setTimeout(function () { return i.emit('init'); }, 0), d = !0);
    } function p(n) { var t = f.draggingClass; n === 'pointerDown' ? addClass(e, t) : removeClass(e, t); } function g() {
        var e = f.selectedClass,
            n = y(!0); w(!0).forEach(function (n) { return removeClass(o[n], e); }), n.forEach(function (n) { return addClass(o[n], e); });
    } function x(n, t) {
        a.add(n, 'focus', function () {
            var n = Math.floor(t / f.slidesToScroll),
                r = t ? n : t; e.scrollLeft = 0, D(r);
        }, !0);
    } function S(e) { void 0 === e && (e = {}); var n = _extends({ startIndex: t.index.get() }, e); h(), v(n), i.emit('reInit'); } function h() {
        var n = f.selectedClass,
            r = f.draggableClass; t.dragHandler.removeActivationEvents(), t.dragHandler.removeInteractionEvents(), t.animation.stop(), a.removeAll(), t.translate.clear(), t.slideLooper.clear(o), removeClass(e, r), o.forEach(function (e) { return removeClass(e, n); }), i.off('select', g), i.off('pointerUp', g), i.off('pointerDown', p), i.off('pointerUp', p);
    } function y(e) {
        void 0 === e && (e = !1); var n = t[e ? 'target' : 'location'].get(),
            r = f.loop ? 'removeOffset' : 'constrain'; return t.slidesInView.check(t.limit[r](n));
    } function w(e) { void 0 === e && (e = !1); var n = y(e); return t.snapIndexes.filter(function (e) { return n.indexOf(e) === -1; }); } function D(e) { t.scrollBody.useDefaultMass().useDefaultSpeed(), t.scrollTo.index(e, 0); } return v(n), { canScrollNext: function () { var e = t.index; return f.loop || e.get() !== e.max; }, canScrollPrev: function () { var e = t.index; return f.loop || e.get() !== e.min; }, clickAllowed: function () { return t.dragHandler.clickAllowed(); }, containerNode: function () { return r; }, dangerouslyGetEngine: function () { return t; }, destroy: function () { d && (h(), d = !1, t = {}, i.emit('destroy')); }, off: l, on: s, previousScrollSnap: function () { return t.indexPrevious.get(); }, reInit: u, scrollNext: function () { var e = t.index.clone().add(1); t.scrollBody.useDefaultMass().useDefaultSpeed(), t.scrollTo.index(e.get(), -1); }, scrollPrev: function () { var e = t.index.clone().add(-1); t.scrollBody.useDefaultMass().useDefaultSpeed(), t.scrollTo.index(e.get(), 1); }, scrollProgress: function () { var e = t.location.get(); return t.scrollProgress.get(e); }, scrollSnapList: function () { var e = t.scrollProgress.get; return t.scrollSnaps.map(e); }, scrollTo: D, selectedScrollSnap: function () { return t.index.get(); }, slideNodes: function () { return o; }, slidesInView: y, slidesNotInView: w };
} export default EmblaCarousel;
