'use strict';

import MessageBus from '../messagesBusEndpoint';
import { observeCookie } from 'shared/js/util/cookies-observer';
import { b64DecodeUnicode, getCookieByName } from 'shared/js/util/cookies-operation';
import _ from 'shared/js/underscore';
import { getLogger } from 'shared/js/dev-mode';

const logger = getLogger();

/**
 * Register's cookies-based messages.
 */
class MessageBusCookieMessage extends MessageBus {
    handle(value) {
        let self = this;
        try {
            const messages = JSON.parse(b64DecodeUnicode(value));
            if (Array.isArray(messages)) {
                messages.forEach((item) => {
                    self.forward(item.content, item.type, {}, 'generic');
                });
            }
        } catch (exception) {
            // Do nothing, silently skip.
            logger.error(exception);
        } finally {
            // Remove messages.
            document.cookie = 'public.messages=W10=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
    }

    register(receiver) {
        let self = this;
        const initialValue = getCookieByName('public.messages', false);
        // Defer execution to finalize registering of event listeners.
        _.delay(() => {
            self.handle(initialValue);
            observeCookie('public.messages', (prop, value) => {
                if (prop === 'value') {
                    if (document.hidden) {
                        _.delay(() => self.handle(value), 100);
                    } else {
                        self.handle(value);
                    }
                }
            }, (rawValue) => {
                // Custom value processor, in order to handle corner-cases.
                if (rawValue === '[]' || rawValue === '' || typeof rawValue === 'undefined') {
                    return undefined;
                }
                return rawValue;
            }, true);
        }, 10);
        super.register(receiver, 'generic');
    }
}

export default MessageBusCookieMessage;
