'use strict';

import MessageBus from '../messagesBusEndpoint';
import messagesConst from '../messagesConst';
import $ from '../../resolvers/resolveJquery';

/**
 * Register's jQuery ajax event listener.
 */
class MessageBusJqueryAjax extends MessageBus {
    register(receiver) {
        let self = this;
        $(window.document).on('ajaxSend', function (event, jqxhr) {
            jqxhr.then(function (response, responseType) {
                if ((responseType === 'error' || (jqxhr.status > 399 && jqxhr.status < 600))) {
                    self.forward(
                        messagesConst.REQUEST_ERROR_5xx,
                        messagesConst.LEVEL_ERROR,
                        {
                            response: response,
                            responseType: responseType,
                            jqxhr: jqxhr
                        }
                    );
                }
                if (response instanceof Object
                    && response.error
                    && response.errorLocalizedFlag) {
                    const errorMessage = response.errorMessage || messagesConst.ERROR_GENERIC;
                    self.forward(errorMessage, messagesConst.LEVEL_ERROR, {
                        response: response,
                        responseType: responseType,
                        jqxhr: jqxhr
                    });
                }
                if (response instanceof Object) {
                    self.forward(messagesConst.REQUEST_SUCCESS, messagesConst.LEVEL_SUCCESS, {
                        response: response,
                        responseType: responseType,
                        jqxhr: jqxhr
                    });
                }
            });
        });
        super.register(receiver, 'ajax');
    }
}

export default MessageBusJqueryAjax;
