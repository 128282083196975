'use strict';

export const rules = [
    {
        actionIds: ['Order-Confirm', 'Order-KlarnaConfirm', 'Adyen-ShowConfirmation'],
        callback: () => {
            // Reflects logic from legacy code-base @see app-project/app_hsng/cartridge/templates/default/checkout/confirmation/confirmation.isml:77
            addEventListener('load', () => {
                document.dispatchEvent(
                    new CustomEvent('Voyado-monitor-empty-cart', {
                        detail: {
                            empty: true
                        }
                    })
                );
            });
        }
    }
];
