/* jshint esversion: 6 */
/* eslint-disable no-unused-vars */

'use strict';

import MessagesMgr from 'shared/js/messagesMgr';
import _ from 'shared/js/underscore';

const FAILED_TO_RETRIEVE_DATA_MESSAGE = 'Failed to retrieve the promotions information. Please, try again later.';

/**
 * Formats a timestamp into human-friendly string line.
 *
 * @param {String} date
 * @return {string}
 */
function formatDate(date) {
    date = new Date(date);

    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return year + '-' + month + '-' + day;
}

$(document).ready(function () {
    $('body').on('click', '.promotion-input', function () {
        const messageContainer = $('#js-promotion-messages');
        if ($(this).is(':checked')) {
            MessagesMgr.success(messageContainer.data('promotion-applied-msg'));
        } else {
            MessagesMgr.warning(messageContainer.data('promotion-misapplied-msg'));
        }
    });
});

/**
 * Client data provider class.
 */
class Client {
    /**
     * Contains the URL to the target endpoint for fetching the data.
     * @type {String}
     */
    #endpointGet;

    /**
     * Contains the URL to the target endpoint for pushing the activation state.
     * @type {String}
     */
    #endpointPost;

    /**
     * The only primary model where and from which will be resolved data for the view.
     * Used to notify the view about changes and vise versa.
     * @type {Object}
     */
    #model;

    /**
     * Internal data structure for storing the fetched data from the server.
     * @type {{applied: [], redeemed: [], count: [], active: [], initialized: boolean, items: []}}
     */
    static #prototypeStructure = {
        items: [],
        count: [],
        active: [],
        applied: [],
        redeemed: [],
        initialized: false,
        isLoading: false,
        isEmpty: false,
        noPromotionsImgUrl: ''
    };

    /**
     * @param {Object} model
     * @param {String} endpointGet
     * @param {String} endpointPost
     * @constructor
     */
    constructor(model, endpointGet, endpointPost) {
        this.#model = model;
        Client.#copy(Client.#prototypeStructure, this.#model);
        this.#endpointGet = endpointGet;
        this.#endpointPost = endpointPost;
    }

    /**
     * Makes call to the endpoint and processes response.
     *
     * @param {Boolean} [reFetchFlag=false]
     * @return {Promise<Response>}
     */
    async retrieve(reFetchFlag) {
        reFetchFlag = reFetchFlag || false;
        let self = this;
        let params = {};
        let query;
        this.#model.isLoading = true;
        if (reFetchFlag) {
            params.reload = 1;
        }

        // Generate query.
        query = Object.keys(params)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
            .join('&');

        // Make call.
        return fetch(`${this.#endpointGet}?${query}`).then(stream => {
            return stream.json().then(response => {
                self.#model.isLoading = false;
                if (response.code === 0) {
                    self.#model.initialized = true;
                    Client.#copy(response.data, self.#model);
                    return response;
                }
                const TRANSLATED_ERROR_MESSAGE = $('.reload-data').data('failed-msg');
                MessagesMgr.error(TRANSLATED_ERROR_MESSAGE || FAILED_TO_RETRIEVE_DATA_MESSAGE);
                return null;
            });
        }).catch(() => {
            self.#model.isLoading = false;
        });
    }

    /**
     * Changes the state of the promotions and returns updated data.
     *
     * @return {Promise<Response>}
     */
    async persist() {
        let self = this;
        const data = _.map(this.#model.items, item => {
            return {
                id: item.id,
                active: item.active
            };
        });

        self.#model.isLoading = true;
        return fetch(this.#endpointPost, {
            method: 'POST',
            redirect: 'manual',
            case: 'no-cache',
            body: JSON.stringify(data)
        }).then(stream => {
            return stream.json().then(response => {
                self.#model.isLoading = false;
                if (response.code === 0) {
                    Client.#copy(response.data, self.#model);
                    self.#model.initialized = true;
                    return response;
                }
                MessagesMgr.error(response.message);
                return null;
            }).catch(() => {
                self.#model.isLoading = false;
            });
        });
    }

    /**
     * @return {Boolean}
     */
    get isLoading() {
        return this.#model.isLoading;
    }

    /**
     * Private getter for items. Invokes fetching of data if still does not have it.
     *
     * @return {Object}
     */
    get data() {
        if (!this.#model.initialized) {
            (async () => {
                await this.retrieve().catch(reason => {
                    const TRANSLATED_ERROR_MESSAGE = $('.reload-data').data('failed-msg');
                    MessagesMgr.error(TRANSLATED_ERROR_MESSAGE || FAILED_TO_RETRIEVE_DATA_MESSAGE);
                });
            })();
        }

        return this.#model;
    }

    /**
     * Returns data structure consumable by the AlpineJS markup processor.
     *
     * @return {Object[]}
     */
    static #formatRecords(data) {
        let items = typeof data === 'object' && Array.isArray(data.items)
            ? Array.from(data.items)
            : [];
        let result = [];

        for (let item of items) {
            let expiration = '';
            let showDate = true;
            if (_.isString(item.dateEnd) && item.dateEnd) {
                expiration += formatDate(item.dateEnd);
            }

            if (expiration === '') {
                showDate = false;
            }

            result.push({
                id: item.id,
                dataStart: item.dateStart,
                dataEnd: item.dateEnd,
                title: item.title,
                description: item.description,
                imageUrl: item.imageUrl,
                active: data.active.indexOf(item.id) !== -1,
                applied: data.applied.indexOf(item.id) !== -1,
                redeemed: data.redeemed.indexOf(item.id) !== -1,
                expiration: expiration,
                showDate: showDate,
                count: item.count,
                countByStatus: item.countByStatus,
                status: item.status
            });
        }
        return result;
    }

    /**
     * Return true/false depends on number of promotions
     *
     * @return {Boolean}
     */
    static promotionWrapperIsEmpty(data) {
        let items = typeof data === 'object' && Array.isArray(data.items)
            ? Array.from(data.items)
            : [];

        return items.length < 1;
    }

    /**
     * Return URL for fallback image when there are no promotions assigned for customer
     *
     * @return {String}
     */
    static noPromotionsImgUrl(data) {
        return data.noPromotionsImgUrl;
    }

    /**
     * Util function to copy promotions data between objects.
     *
     * @param {Object} source
     * @param {Object} destination
     */
    static #copy(source, destination) { // eslint-disable-line no-dupe-class-members
        destination.items = Client.#formatRecords(source);
        destination.count = source.count || source.items.length;
        destination.active = source.active;
        destination.applied = source.applied;
        destination.redeemed = source.redeemed;
        destination.isLoading = source.isLoading;
        destination.isEmpty = Client.promotionWrapperIsEmpty(source);
        destination.noPromotionsImgUrl = Client.noPromotionsImgUrl(source);
    }
}

export default Client;
