'use strict';

import messagesConst from './messagesConst';

/**
 * Generic class to build custom message capture.
 */
class MessagesBusEndpoint {
    #receiver;

    #name;

    register(receiver, name) {
    // Implement custom gateway-capture logic by overriding this method.
        this.#receiver = receiver;
        this.#name = name;
    }

    forward(message, type, context, source) {
        this.#receiver({
            message: message,
            type: type || messagesConst.LEVEL_INFORMATION,
            context: context,
            source: source || this.#name
        });
    }
}

export default MessagesBusEndpoint;
