'use strict';

/**
 * This is an adapter over the toastr messages lib which provides pre-defined contract APIs (functions) to be agnostic regardless of the used
 * popup messages solution.
 */

import ToastrInitializer from './messagesToastr';

let toastr = ToastrInitializer();

// Generic defaults.
toastr.options.newestOnTop = true;
toastr.options.positionClass = 'toast-bottom-center';
toastr.options.preventDuplicates = true;
toastr.options.showDuration = 300;
toastr.options.hideDuration = 1000;
toastr.options.timeOut = 15000;

/**
 * Routine function to await translations loading.
 *
 * @param {'warning'|'success'|'error'|'info'} action
 * @param {string} message
 * @param {string} [title='']
 * @param optionsOverride
 */
const showMessage = (action, message, title, optionsOverride) => {
    message = message || '';
    title = title || '';
    toastr[action](message, title, optionsOverride);
};

module.exports = (function () {
    let initialized = false;

    const MessageMgr = {
        warning: function (message, title, optionsOverride) {
            if (typeof message === 'undefined') {
                return;
            }
            initialized = true;
            showMessage('warning', message, title, optionsOverride || { timeOut: 0, extendedTimeOut: 0 });
        },
        success: function (message, title, optionsOverride) {
            if (typeof message === 'undefined') {
                return;
            }
            initialized = true;
            showMessage('success', message, title, optionsOverride);
        },
        error: function (message, title, optionsOverride) {
            if (typeof message === 'undefined') {
                return;
            }
            initialized = true;
            showMessage('error', message, title, optionsOverride);
        },
        info: function (message, title, optionsOverride) {
            if (typeof message === 'undefined') {
                return;
            }
            initialized = true;
            showMessage('info', message, title, optionsOverride);
        },
        remove: function ($toastElement) {
            if (!initialized) {
                return;
            }
            toastr.remove($toastElement);
        },
        clear: function ($toastElement, clearOptions) {
            if (!initialized) {
                return;
            }
            toastr.clear($toastElement, clearOptions);
        },
        setOptions: function (options) {
            if (typeof options === 'undefined') {
                return;
            }
            initialized = true;
            toastr.options = options;
        },
        getOptions: function () {
            return toastr.options;
        },
        setOption: function (option, value) {
            if (typeof option === 'undefined') {
                return;
            }
            initialized = true;
            toastr.options[option] = value;
        },
        getOption: function (option) {
            return toastr.options[option];
        },
    };

    window.MessagesMgr = MessageMgr;

    return MessageMgr;
}());
