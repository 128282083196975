'use strict';

import EventDispatcher from './eventDispatcher/dispatcher';

// Do not wait for shared components being fully loaded, because configuration requires
// immediate availability.
// document.addEventListener('custom:shared-components-loaded', () => {
let plugins = [];
const collectEventsEvent = new CustomEvent(
    'integration:event-provider',
    {
        detail: {
            dispatcher: EventDispatcher,
            plugins: plugins
        }
    }
);
// Small delay to allow processing of all bundled event listeners.
setTimeout(() => {
    document.dispatchEvent(collectEventsEvent);
    plugins.forEach(plugin => EventDispatcher.register(plugin));
}, 1);
// });

export default EventDispatcher;
