'use strict';

import Provider from 'storefront/shared/integration/eventDispatcher/provider';
import FeatureFlagProvider from 'theme/js/shared/featureFlagRegistrar';

// No events to listen to.
const LISTEN_ON = [''];
class EventProvider extends Provider {
    // This even provider has to be created AFTER CS data is resolved.
    constructor() {
        super();
    }

    isApplicable(container, meta) { // eslint-disable-line no-unused-vars, class-methods-use-this
        return typeof container === 'object'
            && LISTEN_ON.indexOf(container.type) > -1
            && FeatureFlagProvider.get('gmf.integration.active', false);
    }

    generate(container, meta) {
        let result = [];

        if (!this.isApplicable(container, meta)) {
            return result;
        }

        return result;
    }
}

export default EventProvider;
