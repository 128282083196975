'use strict';

import observable from './observable';
import { getCookieByName } from './cookies-operation';

const pollingRate = 250;
let cookiesList = new Map();

const getCookie = (cookie, decode) => getCookieByName(cookie, decode);

const iterateCookie = (cookie, caster, decode) => {
    let observableCookie = cookiesList.get(cookie);
    const cookieValue = getCookie(cookie, decode);
    const cookieValueCaster = caster(cookieValue);
    if (observableCookie && cookieValueCaster !== observableCookie.value) {
        observableCookie.value = cookieValueCaster;
    }
    const interval = setInterval(() => {
        iterateCookie(cookie, caster);
        clearInterval(interval);
    }, pollingRate);
};

export const observeCookie = function (cookie, callback, caster, decode) {
    caster = typeof caster === 'function' ? caster : (value) => value;
    let observableCookie;
    if (cookiesList.get(cookie)) {
        observableCookie = cookiesList.get(cookie);
    } else {
        observableCookie = observable({
            value: getCookie(cookie, decode)
        });
        cookiesList.set(cookie, observableCookie);
        iterateCookie(cookie, caster, decode);
    }
    observableCookie.observe(callback);
};

