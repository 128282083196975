'use strict';

import Provider from '../eventDispatcher/provider';
import FeatureFlagProvider from '../../featureFlagRegistrar';

const LISTEN_ON = ['custom:cart:updateQty', 'custom:cart:empty', 'VOYADO'];

class EventProvider extends Provider {
    isApplicable(container, meta) { // eslint-disable-line no-unused-vars, class-methods-use-this
        return typeof container === 'object'
            && LISTEN_ON.indexOf(container.type) > -1
            && FeatureFlagProvider.get('voyado.tracker.active', false);
    }

    generate(container, meta) {
        let result = [];

        if (!this.isApplicable(container, meta)) {
            return result;
        }

        switch (container.type) { // NOSONAR
        case 'custom:cart:updateQty':
            // TODO Probably add check for data.source.basket
            if (container.data && container.data.source) {
                result.push(new CustomEvent('Voyado-monitor-cart', {
                    detail: {
                        items: container.data.source.items,
                        locale: container.data.source.locale
                    }
                }));
            }
            break;
        case 'custom:cart:empty':
            if (container.data) {
                result.push(new CustomEvent('Voyado-monitor-empty-cart', {
                    detail: {
                        empty: true
                    }
                }));
            }
            break;
        default:
        }

        return result;
    }
}

export default EventProvider;
