let $ = window.jQuery || window.$ || require('jquery');

const SOURCE_SELECTOR = '#klarna-upsell__timer';
const SOURCE_HOLDER_SELECTOR = '#klarna-upsell__timer-holder';
const TARGET_WRAPPER_SELECTOR = '#klarna-upsell__timer-wrapper';

/**
 * The next component is responsible to move back and forth the timer into dynamic content part
 * that is resolved with dedicated callout to the backend.
 *
 * Since slot is resolved afterwards based on current session/basket data,
 * there is no way to apply alpinejs widget over that content once main widget is loaded.
 *
 * Due to that the timer is initiated once main widget is rendered, where then is put into
 * the specified by placeholder place in the resolved from the backend content slot.
 *
 * In case of updating the content slot (i.e. at least during testing and development since
 * confirmation page is by design is not reloadable) - the timer is put back into holder and
 * applied again once new wrapper is processed as a DOM element in the rendered content slot.
 *
 * @returns {{apply: Function, revert: Function}}
 */
export default () => ({
    revert: () => {
        if ($(TARGET_WRAPPER_SELECTOR).length && $(TARGET_WRAPPER_SELECTOR).find(SOURCE_SELECTOR).length) {
            $(SOURCE_SELECTOR).hide();
            $(SOURCE_SELECTOR).appendTo(SOURCE_HOLDER_SELECTOR);
        }
    },
    apply: () => {
        if ($(SOURCE_HOLDER_SELECTOR).length
            && $(TARGET_WRAPPER_SELECTOR).length
            && $(SOURCE_HOLDER_SELECTOR).find(SOURCE_SELECTOR).length) {
            $(SOURCE_SELECTOR).appendTo(TARGET_WRAPPER_SELECTOR);
            $(SOURCE_SELECTOR).show();
        }
    }
});
