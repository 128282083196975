import _ from 'shared/js/underscore';

export default (expiry) => {
    return {
        expiry: expiry,

        remaining: null,

        interval: null,

        callbacks: [],

        init() {
            // Do not call: this.reset(this.expiry)
            // on initialization, do it only with reset function.
        },

        addCallback(callback) {
            if (typeof callback === 'function') {
                this.callbacks.push(callback);
            }
        },

        reset(newExpiry) {
            // For debugging: console.log('New expiry: ' + newExpiry);
            let self = this;
            if (newExpiry && newExpiry > 0) {
                this.expiry = newExpiry;
                this.setRemaining();
                this.interval = setInterval(() => {
                    self.setRemaining();
                }, 1000);
            } // Otherwise - do not setup timer.
        },

        clear() {
            this.remaining = 0;
            clearInterval(this.interval);
        },

        setRemaining() {
            const diff = this.expiry - new Date().getTime();
            if (diff > 0) {
                this.remaining = parseInt(diff / 1000); // TODO do not go below zero
            } else if (_.isNaN(diff)) {
                this.remaining = 0;
            } else {
                this.clear();
                this.callbacks.forEach((callback) => callback());
            }
        },

        days() {
            return {
                value: this.remaining / 86400,
                remaining: this.remaining % 86400
            };
        },

        hours() {
            return {
                value: this.days().remaining / 3600,
                remaining: this.days().remaining % 3600
            };
        },

        minutes() {
            return {
                value: this.hours().remaining / 60,
                remaining: this.hours().remaining % 60
            };
        },

        seconds() {
            return {
                value: this.minutes().remaining,
            };
        },

        format(value) {
            return ('0' + parseInt(value)).slice(-2);
        },

        time() {
            return {
                minutes: this.format(this.minutes().value),
                seconds: this.format(this.seconds().value),
                active: this.remaining > 0
            };
        },

        bind: {
            ['x-bind:class']() {
                if (this.remaining === 0) {
                    return 'klarna-upsell__timer-time--expired';
                }
                return 'klarna-upsell__timer-time';
            }
        },
    };
};
