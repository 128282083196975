/* jshint esversion: 6 */

'use strict';

let handlers = Symbol('handlers');

/**
 * Converts the variable into observable.
 *
 * @param {Object} target
 * @return {Object|Proxy}
 */
function observable(target) {
    /**
     * Handlers storage.
     * @type {*[]}
     */
    target[handlers] = [];

    /**
     * Function to register handlers.
     *
     * @param {Function} handler
     */
    target.observe = function (handler) {
        this[handlers].push(handler);
    };

    // Create a proxy to react on changes.
    return new Proxy(target, {
        set(instance, property, value, receiver) { // eslint-disable-line
            let success = Reflect.set(...arguments); // Forwards the call to an object.
            if (success) { // If no error were triggered while setting the property - call handlers.
                instance[handlers].forEach(handler => handler(property, value));
            }
            return success;
        }
    });
}


export default observable;
