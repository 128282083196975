/* jshint esversion: 6 */
/* eslint-disable no-unused-vars */

import Alpine from 'shared/js/alpinejs';
import MessagesMgr from 'shared/js/messagesMgr';
import _ from 'shared/js/underscore';
let $ = window.jQuery || window.$ || require('jquery');

import Client from './client.js';
import timer from './component/timer';
import slider from './component/slider';
import timerInjector from './component/timerInjector';
import widgetStrategy from './widgetStrategy';

// CONSTS
// Messages (via translations).
const FAILED_TO_RETRIEVE_DATA_MESSAGE = 'Unable to process the request.';
// Wait period for add to the order action.
const WAIT_PERIOD = 1500;


/**
 * Performs refresh of the checkout confirmation page with new order content & totals.
 * @param {Object} data
 */
const refreshSummary = (data) => {
    let SummaryService = require('theme/js/checkout/summary');
    SummaryService.updateTotals(data.order.totals);
    $('.card.order-product-summary').html(data.summaryItemsHtml);
    $('#klarna-checkout-iframe').hide();
};

const addToOrder = _.throttle(_.debounce(function (provider, callback) {
    provider.persist()
        .then(response => {
            if (response && response.code === 0) {
                callback('confirmation');
                if (response.data && response.data.order) {
                    refreshSummary(response.data);
                }
            } else {
                callback('exception');
            }
        })
        .catch(reason => {
            callback('exception');
            MessagesMgr.error(FAILED_TO_RETRIEVE_DATA_MESSAGE);
        });
}, WAIT_PERIOD), WAIT_PERIOD);

/**
 * The AlpineJS component.
 * @return {{init: init, items: (function(): [])}}
 */
export default (bootstrap) => ({
    /**
     * @type {String} Currently to distinguish "confirmation".
     */
    widgetType: bootstrap.widgetType,

    orderNoReference: bootstrap.orderNo,

    debugModeFlag: !!bootstrap.debugFlag,

    widgetStrategy: null,

    timerInjectorInstance: null,

    componentTimer: {},

    componentSlider: {},

    contentMediator: Alpine.reactive({
        content: null,
        version: 0,
        export: {
            'x-html'() { return ''; },
            'x-data'() { return {}; },
        },
        callbacks: [],
        activeScene: 'start', // Available options: 'start', 'timeout', 'exception', 'confirmation'
    }),

    // Data provider class: resolves target content (with products).
    dataProvider: new Client(
        Alpine.reactive({ items: [], orderNo: bootstrap.orderNo, strategyType: bootstrap.widgetType }),
        'KlarnaUpsell-ProductsListing',
        'KlarnaUpsell-Purchase',
        {
            debugModeFlag: !!bootstrap.debugFlag
        }
    ),

    /**
     * DataProvider initialization for the component.
     */
    init() {
        let self = this;
        this.widgetStrategy = widgetStrategy(this);

        this.timerInjectorInstance = this.timerInjectorInstance || timerInjector();
        this.componentTimer = timer();
        this.componentSlider = slider(this);
        this.widgetStrategy.init();

        this.$nextTick(() => {
            self.dataProvider.retrieve((content) => {
                self.contentMediator.version++;
                self.contentMediator.content = content;
            })
                .finally(() => {
                    self.dataProvider.startTimer((expiryTimestamp) => {
                        self.componentTimer.reset(expiryTimestamp);
                    }, bootstrap.expiry);
                })
                .catch(reason => {
                    if (self.debugModeFlag) {
                        MessagesMgr.error(FAILED_TO_RETRIEVE_DATA_MESSAGE);
                    }
                });
        });
    },

    isVisible() {
        return this.dataProvider.isActive()
            && this.dataProvider.data.items
            && this.dataProvider.data.items.length;
    },

    /**
     * @return {void}
     */
    submit() {
        let self = this;
        addToOrder.cancel();
        addToOrder(this.dataProvider, (scene) => {
            // Prevent from changing the scene to timeout after switch to another (non-start) one.
            _.debounce(() => {
                if (scene !== 'start') {
                    self.componentTimer.clear();
                }
                self.$scroll(0, { behavior: 'auto' });
            }, 10)();
            self.contentMediator.activeScene = scene;
        });
    },

    /**
     * Indicates either the widget is performing a callout.
     *
     * @type {Boolean}
     */
    isLoading() {
        return this.dataProvider.isLoading;
    },

    /**
     * Returns instance of countdown timer.
     *
     * @returns {null|{init(): void, hours(): {value, remaining}, seconds(): {value: *}, minutes(): {value, remaining}, format(*): *|string, days(): {value, remaining}, interval: null, expiry: *, time(): {seconds: *, minutes: *}, setRemaining(): void, remaining: null}|*}
     */
    get timer() {
        return this.componentTimer;
    },

    /**
     * @returns {null|Object}
     */
    get slider() {
    // Do not re-initialize slider for bySlotType strategy since it breaks the linkage between
    // ViewModel and AlpineJS View: this.componentSlider = slider(this);
        return this.componentSlider;
    },

    /**
     * Returns a sub-component with rendered content.
     */
    get contentRender() {
        return this.contentMediator.export;
    },

    /**
     * Returns a sub-component for content wrapper.
     */
    klarnaUpsellContainer: {
        ['x-bind:class']() {
            return 'upsell-widget'; // Customization entry point.
        }
    },

    /**
     * Returns a toolbox reload component.
     */
    controllerReload: { // TODO Show only in test mode.
        ['@click']() { // NOSONAR
            let self = this;
            this.dataProvider
                .retrieve((content) => {
                    self.contentMediator.version++;
                    self.contentMediator.content = content;
                })
                .catch(reason => {
                    if (self.debugModeFlag) {
                        MessagesMgr.error(FAILED_TO_RETRIEVE_DATA_MESSAGE);
                    }
                });
        },
        'x-show'() {
            return !this.dataProvider.isUsed();
        }
    },

    /**
     * Submit data.
     */
    controllerSubmit: {
        ['@click']() {
            this.submit();
        },
        'x-show'() {
            return this.dataProvider.isActive()
                && this.dataProvider.timestamp.value
                && this.dataProvider.timestamp.active
                && this.contentMediator.activeScene === 'start';
        },
        'x-bind:disabled'() {
            return this.dataProvider.selectedItemsCount() === 0;
        },
    // 'x-text'() {
    //     return $t('Add to Order');
    // }
    },

    /**
     * Select custom scene.
     */
    controllerSceneSelector: {
        open: false,

        options: ['confirmation', 'exception', 'timeout'],

        toggle() {
            if (this.open) {
                return this.close();
            }

            this.$refs.klarnaUpsellSceneSelector.focus();

            this.open = true;
        },

        close(focusAfter) {
            if (!this.open) {
                return;
            }

            this.open = false;

            focusAfter && focusAfter.focus();
        },

        select(value) {
            this.contentMediator.activeScene = value;
        }
    }
});
