'use strict';

let $ = window.jQuery || window.$ || require('jquery');

export default () => ({
    /**
     * Changes the active scene.
     *
     * @param {Object} rootElement
     * @param {String} scene
     */
    changeScene(rootElement, scene) {
        let element = $(rootElement).find(`[data-scene=${scene}]`);
        let elementsToHide = $(rootElement).find('[data-scene]').not(`[data-scene=${scene}]`);
        if (element.length) {
            element.show();
            elementsToHide.hide();
        }
    }
});
