'use strict';

import { getCookieByName } from 'shared/js/util/cookies-operation';
import _ from 'shared/js/underscore';
export const isFunctionalAllowed = () => {
    // Naming originates from the earlier implementation,
    // ex., @see SFCC-394
    let cookie = getCookieByName('set_functional_cookies');
    return _.toBoolean(cookie);
};

export const isRequiredCookieAllowed = () => {
    // Naming originates from the earlier implementation,
    // ex., @see SFCC-1124
    let cookie = getCookieByName('set_necessary_cookies');
    return _.toBoolean(cookie);
};

export default {
    isFunctionalAllowed,
    isRequiredCookieAllowed
};
