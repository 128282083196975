'use strict';

// noinspection ES6UnusedImports
// eslint-disable-next-line no-unused-vars
import Provider from './provider'; // NOSONAR

class Plugin {
    /**
     * @param {Function} next
     * @param {Provider|Function} subject
     * @param {*} container
     * @param {*} [meta=undefined]
     */
    // noinspection JSUnusedLocalSymbols
    generate(next, subject, container, meta) { // eslint-disable-line class-methods-use-this
        return next.apply(subject, [container, meta]); // To be implemented by corresponded plugin
    }

    /**
     * @param {Function} next
     * @param {Provider|Function} subject
     * @param {*} container
     * @param {*} [meta=undefined]
     */
    // noinspection JSUnusedLocalSymbols
    isApplicable(next, subject, container, meta) { // eslint-disable-line class-methods-use-this
        return next.apply(subject, [container, meta]); // To be implemented by corresponded plugin
    }
}

export default Plugin;
