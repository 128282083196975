'use strict';

import Xml2json from 'shared/js/xml2json';

/**
 * @param {string} content
 * @return {string}
 */
const decodeContent = (content) => {
    return decodeURIComponent(atob(content));
};

/**
 * Converts XML text to the JSON which can be consumed by setItems function.
 *
 * @param {string} content
 * @param {string} sectionId
 * @param {string} [slot]
 * @return {*[]}
 */
export function transformXmlResponseToJson(content, sectionId, slot) {
    slot = slot || 'slot';
    const data = Xml2json(content, [{
        byTagName: slot,
        callback: (element, buffer) => {
            buffer.items = buffer.items || {};
            const name = element.getAttribute('name');
            const contentType = element.getAttribute('type');
            const value = contentType === 'html'
                ? element.innerHTML.trim()
                : element.textContent.trim();
            if (contentType === 'json') {
                buffer.items[name] = { /* eslint-disable-line no-constant-condition */
                    id: name,
                    value: value
                } === 'null' ? null : JSON.parse(decodeContent(value));
            } else {
                buffer.items[name] = {
                    id: name,
                    value: value
                };
            }
        }
    }, {
        byTagName: `${slot}s`,
        callback: (element, buffer) => {
            buffer.version = parseFloat(element.getAttribute('version').split(',')[0].replace(/\D/g, ''));
        }
    }]);
    return {
        data: {
            items: {
                [sectionId]: data
            }
        }
    };
}
