/* jshint esversion: 6 */

'use strict';

import CheckoutCalculator from 'theme/js/checkout/summary';
let $ = window.jQuery || window.$ || require('jquery');

$(() => {
    // Since Klarna checkout inclusion eliminates parts of SFRA storefront components,
    // add a minimal bus-mechanism to allow updating totals on demand via custom event listener.
    // Current one - updates totals including discounts lines.
    $('body').on('checkout:updateCheckoutView', (evt, data) => {
    // The base version of "CheckoutCalculator.updateTotals" expects to receive totals
    // in arguments. If triggered event does include data structure on level-up - conditionally
    // resolve totals from it.
        if (!data.order) {
            return;
        }
        let totals = data.order.totals && data.order.totals.orderLevelDiscountTotal
            ? data.order.totals
            : data.order;
        if (totals) {
            CheckoutCalculator.updateTotals(totals);
        }
    });
});
