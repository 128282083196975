'use strict';

import MessageMgr from '../messages';

let handle = function (message) {
    if (!message.message || message.source !== 'generic') {
        return;
    }
    if (message.type && MessageMgr[message.type] instanceof Function) {
        MessageMgr[message.type](message.message);
    }
};

export default handle;
