'use strict';

// The current scipt works as an adapter between SFCC native slots rendering content
// (responsible to render product tiles with corresponded add-to-cart buttons, etc)
// and state-based rendering engine used by Alpinejs.

let $ = window.jQuery || window.$ || require('jquery');

const STATE_INACTIVE = 0; // Button cannot be pressed because of fixable exception or timer run out.
const STATE_DISABLED = 1; // Button is disabled completely, product cannot be added to the order.
const STATE_ACTIVE = 2; // Button is active and product is not selected for adding to the order.
const STATE_ACTIVE_ADDED = 3; // Button is active and product is already selected.

/**
 * @param {Object} element
 * @returns {String|Number}
 */
const getProductId = (element) => {
    const productId = $(element).data('pid');
    return productId || 0;
};

/**
 * Changes the visual state of the button(s) depending on the provided state const.
 *
 * @param {Object} element
 * @param {Number} state
 */
const changeElementState = (element, state) => {
    switch (state) {
    case STATE_ACTIVE:
        $(element).prop('disabled', false);
        $(element).toggleClass('inactive', false);
        $(element).toggleClass('selected', false);
        $(element).toggleClass('failure', false);
        break;
    case STATE_ACTIVE_ADDED:
        $(element).prop('disabled', false);
        $(element).toggleClass('inactive', false);
        $(element).toggleClass('selected', true);
        $(element).toggleClass('failure', false);
        break;
    case STATE_DISABLED:
        $(element).prop('disabled', true);
        $(element).toggleClass('inactive', true);
        $(element).toggleClass('selected', false);
        $(element).toggleClass('failure', true);
        break;
    case STATE_INACTIVE:
    default:
        $(element).prop('disabled', true);
        $(element).toggleClass('inactive', true);
        $(element).toggleClass('selected', false);
        break;
    }
};

export default (dataProvider) => ({
    /**
     * Changes the state of buttons.
     *
     * @param {Object} rootElement
     * @param {Boolean} active
     */
    changeState(rootElement, active) {
        active = active || false;
        let buttons = $(rootElement).find('button.add-to-cart').not('.failure');
        changeElementState(buttons, active ? STATE_ACTIVE : STATE_INACTIVE);
    },

    /**
     * Applies "add to order" action over the "add to basket" buttons.
     *
     * @param {Object} rootElement
     */
    apply(rootElement) {
        let buttons = $(rootElement).find('button.add-to-cart');
        // Append new action.
        buttons.on('click', (event) => {
            event.preventDefault();
            const productId = getProductId(event.target);
            let state;
            if (productId) {
                // state = true when product is selected to be added to the order.
                state = dataProvider.toggleItem(productId);
                changeElementState(event.target, state ? STATE_ACTIVE_ADDED : STATE_ACTIVE);
            } else {
                // TODO Toastr message w/ translation - after @see SFCC-1363
                console.error('No product ID can be resolved for the entry');
                changeElementState(event.target, STATE_DISABLED);
            }
        });
        // Remove <a href="" /> entries.
        $(rootElement).find('a.add-to-cart').remove();
    }
});
