/* jshint esversion: 6 */

'use strict';

/**
 * This is an initial version of the client.
 * It should be left for historical & reference purposes for the time until
 * creating the unified approach of defining the observable Data Providers.
 */

import MessagesMgr from 'shared/js/messagesMgr';

const FAILED_TO_RETRIEVE_DATA_MESSAGE
    = 'Failed to retrieve the promotions information. Please, try again later.';

/**
 * Formats a timestamp into hyman-friendly string line.
 *
 * @param {String} date
 * @return {string}
 */
function formatDate(date) {
    date = new Date(date);

    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return year + '-' + month + '-' + day;
}

/**
 * Client data provider class.
 */
class Client {
    /**
     * Contains the URL to the target endpoint for fetching the data.
     * @type {String}
     */
    #endpoint;
    /**
     * Contains instances to which will be set values fetched from the server.
     * @type {Array<Object>}
     */
    #observers = [];
    /**
     * The only primary model where and from which will be resolved data for the view.
     * Used to notify the view about changes and vise versa.
     * @type {Object}
     */
    #model;
    /**
     * Internal data structure for storing the fetched data from the server.
     * @type {{applied: [], redeemed: [], count: [], active: [], initialized: boolean, items: []}}
     */
    #data = {
        items: [],
        count: [],
        active: [],
        applied: [],
        redeemed: [],
        initialized: false
    };

    /**
     * @param {Object} model
     * @param {String} [endpoint='data.json']
     * @constructor
     */
    constructor(model, endpoint) {
        this.#model = model;
        this.#endpoint = endpoint || 'data.json';
        this.observe(model);
    }

    /**
     * Makes call to the endpoint and processes response.
     *
     * @return {Promise<void>}
     */
    async retrieve() {
        let self = this;
        await fetch(this.#endpoint).then(stream => {
            stream.json().then(response => {
                if (response.code === 0) {
                    Client.#copy(response.data, self.#data);
                    self.#data.initialized = true;
                    self.#notify();
                } else {
                    MessagesMgr.error(FAILED_TO_RETRIEVE_DATA_MESSAGE);
                }
            });
        });
    }

    /**
     * Util function to copy promotions data between objects.
     *
     * @param {Object} source
     * @param {Object} destination
     */
    static #copy(source, destination) {
        destination.items = source.items;
        destination.count = source.count || source.items.length;
        destination.active = source.active;
        destination.applied = source.applied;
        destination.redeemed = source.redeemed;
    }

    /**
     * Iterates over registered observable object and copies changed data to them.
     *
     * @return {void}
     */
    #notify() {
        for (let observer of this.#observers) {
            Client.#copy(this.#data, observer);
        }
    }

    /**
     * Registers new object for updating it on changes in data.
     *
     * @param {Object} instance
     * @return {Object}
     */
    observe(instance) {
        this.#observers.push(instance);
        return instance;
    }

    /**
     * Private getter for items. Invokes fetching of data if still does not have it.
     *
     * @return {Object[]}
     */
    // eslint-disable-next-line
    get #items() { // eslint-disable no-dupe-class-members
        if (!this.#data.initialized) {
            this.retrieve().catch(reason => {
                MessagesMgr.error(FAILED_TO_RETRIEVE_DATA_MESSAGE, reason);
            });
        }

        return this.#data.items;
    }

    /**
     * Returns data structure consumable by the AlpineJS markup processor.
     *
     * @return {Object[]}
     */
    getRecords() {
    // Access the models data for tracking purposes.
        let items = this.#model && Array.isArray(this.#model.items)
            ? Array.from(this.#model.items)
            : []; // NOSONAR
        let result = [];

        for (let item of items) {
            let expiration = formatDate(item.dateStart);
            if (item.dateEnd) {
                expiration += ' - ' + formatDate(item.dateEnd);
            }
            result.push({
                id: item.id,
                dataStart: item.dataStart,
                dataEnd: item.dataEnd,
                title: item.title,
                description: item.description,
                imageUrl: item.imageUrl,
                active: this.#data.active.indexOf(item.id) !== -1,
                applied: this.#data.applied.indexOf(item.id) !== -1,
                redeemed: this.#data.redeemed.indexOf(item.id) !== -1,
                expiration: expiration
            });
        }
        return result;
    }
}

export default Client;
