import _ from 'shared/js/underscore';
import addToOrderInjector from '../component/addToOrderInjector';
// import slider from '../component/slider';
import legacySceneSwitcher from '../component/legacySceneSwitcher';

export default (widgetInstance) => ({
    addToOrderInjectorInstance: addToOrderInjector(widgetInstance.dataProvider),
    legacySceneSwitcher: legacySceneSwitcher(),

    init() {
        let self = this;

        // Callback to replace "add to cart" with "add to order" action.
        widgetInstance.componentTimer.addCallback(() => {
            if (widgetInstance.contentMediator.activeScene !== 'confirmation') {
                widgetInstance.contentMediator.activeScene = 'timeout';
            }
            self.addToOrderInjectorInstance.changeState(
                widgetInstance.$refs.klarnaUpsellProductContainer,
                false
            );
        });

        // Main content renderer.
        widgetInstance.contentMediator.export['x-html'] = _.wrap(
            widgetInstance.contentMediator.export['x-html'],
            () => {
                widgetInstance.contentMediator.source = widgetInstance.dataProvider.data.content;

                widgetInstance.contentMediator.callbacks = [];

                if (widgetInstance.dataProvider.isActive()) {
                    _.debounce(() => {
                        widgetInstance.contentMediator.callbacks.forEach(
                            (callback) => callback(widgetInstance.contentMediator)
                        );
                        self.legacySceneSwitcher.changeScene(
                            widgetInstance.$refs.klarnaUpsellProductContainer,
                            widgetInstance.contentMediator.activeScene
                        );
                    }, 10)();
                }

                // Move timer back to its holder out-side of the dynamic slot content.
                widgetInstance.timerInjectorInstance.revert();
                // Replace placeholder with timer DOM element container for injection.
                widgetInstance.contentMediator.content = widgetInstance.contentMediator.source
                    .replace(/\[\[timer]]/, '<span id="klarna-upsell__timer-wrapper"></span>');

                switch (widgetInstance.contentMediator.activeScene) {
                case 'start':
                    // Postpone injection of slider until new dynamic content is rendered.
                    // Apply add-to-order action on product tiles.
                    widgetInstance.contentMediator.callbacks.push(() => {
                        // Re-initiate slider.
                        // widgetInstance.componentSlider = slider(widgetInstance);
                        // Can be checked for replacement with $nextTick.
                        widgetInstance.componentSlider.init();
                        widgetInstance.timerInjectorInstance.apply();
                        self.addToOrderInjectorInstance.apply(widgetInstance.$refs.klarnaUpsellProductContainer);
                        self.addToOrderInjectorInstance.changeState(
                            widgetInstance.$refs.klarnaUpsellProductContainer,
                            true
                        );
                    });
                    break;
                case 'confirmation':
                case 'timeout':
                case 'exception':
                    break;
                default:
                    console.error(`[Klarna Upsell] Invalid scene ${widgetInstance.contentMediator.activeScene}`);
                }

                return widgetInstance.contentMediator.content;
            }
        );
    },
});
