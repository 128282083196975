'use strict';

/**
 * Class responsible to register and initialize the messages providers.
 */
class MessagesBus {
    listeners;

    /**
     * @param {MessagesBusEndpoint[]} endpoints
     */
    constructor(endpoints) {
        this.listeners = {};
        endpoints.forEach(endpoint => endpoint.register(this.#callback.bind(this)));
    }

    #callback(message) {
        for (const [name, listener] of Object.entries(this.listeners)) {
            try {
                listener(message);
            } catch (exception) {
                console.error(`Error on processing listener ${name}: ${exception.message}`);
            }
        }
    }

    register(name, listener) {
        this.listeners[name] = listener;
    }

    unregister(name) {
        if (this.listeners[name]) {
            delete this.listeners[name];
        }
    }
}

export default MessagesBus;
