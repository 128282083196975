/* jshint esversion: 6 */

'use strict';

import Toolkit from './toolkit';
import Tracker from './tracker';
export default (Alpine) => {
    Alpine.plugin(Toolkit);
    Alpine.plugin(Tracker);
};
