'use strict';

import MessageMgr from './messages';

const ERROR_MESSAGE = 'Unhandled exception, please try again.';

let $ = window.jQuery || window.$ || require('jquery');

$(window.document).on('ajaxSend', function (event, jqxhr) {
    jqxhr.then(function (response, responseType) {
        const toastNotifyUnhandledError = $('.data-holder').data('toast-notify-unhandled-error');

        if (toastNotifyUnhandledError === 'true' && (responseType === 'error' || (jqxhr.status > 399 && jqxhr.status < 600))) {
            MessageMgr.error(ERROR_MESSAGE);
        }

        if (response instanceof Object && response.error) {
            const toastNotifyAnyHandledError = $('.data-holder').data('toast-notify-any-handled-error');

            if (toastNotifyAnyHandledError === 'true' || response.errorLocalizedFlag) {
                const errorMessage = response.errorMessage || ERROR_MESSAGE;
                MessageMgr.error(errorMessage);
            }
        }
    });
});
