'use strict';

import _ from 'shared/js/underscore';

/**
 * Converts data instance to an array which can be consumed by setItems function.
 *
 * @param {Array} items
 * @return {*[]}
 */
export function traverseToSetItems(items) {
    let result = [];
    _.each(items, (value, key) => {
        const index = value && value.id || value && value.key || key;
        if (typeof value === 'object') {
            delete value.id;
            delete value.key;
        }
        if (value.hasOwnProperty('value')) {
            value = value.value;
        }
        result.push({
            key: index,
            value: value
        });
    });
    return result;
}
