'use strict';

import 'shared-main.scss';

const processInclude = function (include) {
    if (typeof include === 'function') {
        include();
    } else if (typeof include === 'object') {
        Object.keys(include).forEach(function (key) {
            if (typeof include[key] === 'function') {
                include[key]();
            }
        });
    }
};

addEventListener('DOMContentLoaded', () => processInclude(require('shared-main.js')));
