/* jshint esversion: 6 */
/* eslint-disable no-unused-vars */

'use strict';

import MessagesMgr from 'shared/js/messagesMgr';
import _ from 'shared/js/underscore';
import UrlService from 'shared/js/url';
import Xml2json from 'shared/js/xml2json';
import observable from 'shared/js/util/observable';
import { getLogger } from 'shared/js/dev-mode';
const logger = getLogger();

const FAILED_TO_RETRIEVE_DATA_MESSAGE = 'Unable to process the request.';

const decodeContent = (content) => {
    return decodeURIComponent(atob(content));
};

/**
 * Client data provider class.
 */
class Client {
    /**
     * Contains the URL to the target endpoint for fetching the data.
     * @type {String}
     */
    endpointGet;

    /**
     * Contains the URL to the target endpoint for adding to the order selected product(s).
     * @type {String}
     */
    endpointPost;

    /**
     * Indicates whether there is ongoing request to retrieve content slot or not.
     * @type {Promise<Response>|null}
     */
    retrieveState;

    /**
     * @type {{value: Number, active: Boolean}}
     */
    timestamp = observable({
        value: 0,
        active: false
    });

    /**
     * The only primary model where and from which will be resolved data for the view.
     * Used to notify the view about changes and vise versa.
     * @type {Object}
     */
    model;

    /**
     * Additional flags to customize client's data retrieval and persistence.
     * @type {{debugModeFlag: Boolean}}
     */
    flags = {};

    /**
     * Internal data structure for storing the fetched data from the server.
     * @type {{initialized: boolean, items: []}}
     */
    static #prototypeStructure = {
        items: [],
        content: '',
        orderNo: '',
        strategyType: '',
        initialized: false,
        isLoading: false,
        usedFlag: false
    };

    /**
     * @param {Object} model
     * @param {String} endpointGet
     * @param {String} endpointPost
     * @param {{debugModeFlag: Boolean}} [flags=undefined]
     * @constructor
     */
    constructor(model, endpointGet, endpointPost, flags) {
        this.model = model;
        this.flags = flags || {};
        Client.#copy(Client.#prototypeStructure, this.model);
        this.endpointGet = UrlService.getControllerUrl(endpointGet);
        this.endpointPost = UrlService.getControllerUrl(endpointPost);
    }

    /**
     * Makes call to the endpoint and processes response.
     *
     * @param {Function} [callback=undefined]
     * @return {Promise<Response>}
     */
    async retrieve(callback) {
        if (this.retrieveState) {
            return this.retrieveState;
        }

        let self = this;
        let params = {
            orderNo: this.model.orderNo,
            type: this.model.strategyType
        };
        let query;

        this.model.isLoading = true;

        // Generate query.
        query = Object.keys(params)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
            .join('&');

        // Make call.
        this.retrieveState = fetch(`${this.endpointGet}?${query}`)
            .then(stream => {
                return {
                    // No need in parsing (however, with DOM document no need in then async callback.
                    // content: new window.DOMParser().parseFromString(stream.text(), 'text/xml'),
                    content: stream.text().then(content => {
                        self.retrieveState = false;
                        self.model.isLoading = false;
                        if (stream.status === 200) {
                            self.model.initialized = true;
                            let data = self.model.strategyType === 'slotBased'
                                ? Xml2json(content, [{
                                    byTagName: 'slot',
                                    callback: (element, buffer) => {
                                        const name = element.getAttribute('name');
                                        const contentType = element.getAttribute('type');
                                        const value = contentType === 'html'
                                            ? element.innerHTML.trim()
                                        // TODO Could be added a safer handler or moved to the parser.
                                            : element.textContent.trim();
                                        if (contentType === 'json') {
                                            buffer[name] = value === 'null' ? null : JSON.parse(decodeContent(value));
                                        } else {
                                            buffer[name] = value;
                                        }
                                    }
                                }])
                                : content;
                            if (data.products) {
                                _.each(data.products.items, (item) => {
                                    item.selected = false;
                                    item.disabled = false;
                                    item.id = item.productId;
                                });
                            }
                            Client.#copy({
                                content: data
                            }, self.model);
                            self.timestamp.active = true; // The last step before return.
                            typeof callback === 'function' && callback(this.model.content);
                            return content;
                        }
                        self.timestamp.active = false;
                        if (self.flags.debugModeFlag) {
                            MessagesMgr.error(FAILED_TO_RETRIEVE_DATA_MESSAGE);
                        }
                        return null;
                    }),
                    response: stream
                };
            })
            .catch((exception) => {
                logger.log(exception);
                self.model.isLoading = false;
                self.timestamp.active = false;
                self.retrieveState = null;
            });

        return this.retrieveState;
    }

    isActive() {
        return this.model.initialized;
    }

    isUsed() {
        return this.model.usedFlag;
    }

    startTimer(callback, expiry) {
        let self = this;

        // Setup an observer.
        this.timestamp.observe((property, value) => {
            if (property === 'value' && value && self.timestamp.active) {
                // Execute callback on setting up the timer expiry if client state is active
                // (i.e. ready to accept user's input).
                callback(value);
            } else if (property === 'active' && self.timestamp.value && value) {
                // Execute postponed activation state with timer start.
                callback(self.timestamp.value);
            }
        });

        this.timestamp.value
            = (new Date().setSeconds((new Date()).getSeconds() + expiry ? expiry : 300));
    // Do not explicitly execute callback, instead listen to observer:
    // commented: callback(this.timestamp.value);
    }

    toggleItem(productSignature) {
        if (!this.timestamp.active) {
            return;
        }
        let item = _.find(this.model.items, { id: productSignature });
        if (!item) {
            item = {
                id: productSignature,
                selected: true
            };
            this.model.items.push(item);
        } else {
            item.selected = !item.selected;
        }
        return item.selected;
    }

    selectedItemsCount() {
        const selected = _.filter(this.model.items, { selected: true });
        return selected.length;
    }

    /**
     * Changes the state of the promotions and returns updated data.
     *
     * @return {Promise<Response>}
     */
    async persist() {
        if (!this.timestamp.active) {
            return new Promise(() => void 0); // eslint-disable-line no-void
        }

        let self = this;
        let params = {
            orderNo: this.model.orderNo
        };
        let query;
        // Generate query.
        query = Object.keys(params)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
            .join('&');

        const data = _(this.model.items).filter(item => item.selected).map(item => {
            return {
                // Set of fields that will logged in BM as order notes.
                id: item.id,
                selected: item.selected
            };
        });
        self.model.isLoading = true;
        return fetch(`${this.endpointPost}?${query}`, {
            method: 'POST',
            redirect: 'manual',
            case: 'no-cache',
            body: JSON.stringify(data)
        }).then(stream => {
            return stream.json().then(response => {
                self.model.isLoading = false;
                if (response.code === 0 && response.data.status) {
                    Client.#copy({
                        content: response.data.replaceContent
                            ? response.data.content
                            : self.model.content
                    }, self.model);
                    self.model.initialized = true;
                    self.timestamp.active = false;
                    if (self.flags.debugModeFlag) {
                        MessagesMgr.success('Your order is updated!');
                        self.model.usedFlag = true;
                    }
                    return response;
                }
                if (self.flags.debugModeFlag) {
                    MessagesMgr.error(response.message);
                }
                return {
                    code: 1
                };
            }).catch(() => {
                self.model.isLoading = false;
            });
        });
    }

    /**
     * @return {Boolean}
     */
    get isLoading() {
        return this.model.isLoading;
    }

    /**
     * Private getter for items. Invokes fetching of data if still does not have it.
     *
     * @return {Object}
     */
    get data() {
        if (!this.model.initialized) {
            (async () => {
                let self = this;
                await this.retrieve().catch(reason => {
                    if (self.flags.debugModeFlag) {
                        MessagesMgr.error(FAILED_TO_RETRIEVE_DATA_MESSAGE);
                    }
                    self.timestamp.active = false;
                });
            })();
        }

        return this.model; // Private properties could not be accessed through Proxy by spec.
    }

    /**
     * Util function to copy content data between objects.
     *
     * @param {Object} source
     * @param {Object} destination
     */
    static #copy(source, destination) { // eslint-disable-line no-dupe-class-members
        destination.content = source.content; // TODO there could be added transformation or remove it.
        destination.items = [].splice.apply(destination.items, [0, 0]).concat(
            destination.content.products ? destination.content.products.items : []);
        destination.isLoading = source.isLoading;
        destination.orderNo = source.orderNo || destination.orderNo; // Fallback to original.
        destination.strategyType = source.strategyType || destination.strategyType; // Fallback to original.
    }
}

export default Client;
