'use strict';

import Provider from './provider';
import Plugin from './plugin';
import _ from 'shared/js/underscore';

class Dispatcher {
    /**
     * @type {Provider[]}
     */
    #eventProviders = [];

    /**
     * @param {Provider|Function} eventProvider
     */
    register(eventProvider) {
        if (eventProvider instanceof Plugin) {
            this.#plugin(eventProvider);
        } else if (eventProvider instanceof Provider || eventProvider instanceof Function) {
            this.#eventProviders.push(eventProvider);
        }
    }

    /**
     * @see https://underscorejs.org/#wrap
     * @param {Plugin} plugin
     */
    #plugin(plugin) {
        this.#eventProviders = _.map((eventProvider) => {
            if (eventProvider instanceof Provider) {
                eventProvider.generate = _.wrap(eventProvider.generate, plugin.generate);
                eventProvider.isApplicable = _.wrap(eventProvider.isApplicable, plugin.isApplicable);
            } else if (eventProvider instanceof Function) {
                eventProvider = _.wrap(eventProvider, plugin.generate);
            }
            return eventProvider;
        });
    }

    /**
     * @param {Event} container
     */
    dispatch(container) { // eslint-disable-line class-methods-use-this
        if (container instanceof Event) {
            document.dispatchEvent(container);
        }
    }

    /**
     * @param {*} container
     * @param {*} [meta=undefined]
     */
    broadcast(container, meta) {
        const self = this;

        if (container && typeof container === 'object'
            && meta && typeof meta === 'object') {
            if (meta.extend) {
                _.extend.apply(_, [...meta.extend,]); // eslint-disable-line comma-spacing
            }
        }

        this.#eventProviders.forEach((eventProvider) => {
            if (eventProvider instanceof Provider) {
                eventProvider.generate(container, meta).forEach(event => self.dispatch(event));
            } else if (eventProvider instanceof Function) {
                eventProvider.apply(null, [container, meta]);
            }
        });
    }
}

export default new Dispatcher();
