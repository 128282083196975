/* jshint esversion: 6 */

'use strict';

import Alpine from 'shared/js/alpinejs';
import widget from './component/klarnaUpsellWidget/widget';

document.addEventListener('alpine:collect-components', function () {
    Alpine.data('klarnaUpsellPersonalization', widget);
});
