import LegacyType from './widget/legacyType';
import BySlotType from './widget/bySlotType';

export default (widgetInstance) => ({
    strategy: null,

    init() {
        if (widgetInstance.widgetType === 'slotBased') {
            this.strategy = BySlotType(widgetInstance);
        } else if (widgetInstance.widgetType === 'legacy') {
            this.strategy = LegacyType(widgetInstance);
        }
        this.strategy.init();
    },

    render(context) {
        context.callbacks = context.callbacks || [];
        context.export = context.export || {};
        this.strategy.render(context);
    }
});
