'use strict';

import _ from 'shared/js/underscore';
import { getCookieByName } from 'shared/js/util/cookies-operation';
import { observeCookie } from 'shared/js/util/cookies-observer';

const getResolvedPromise = (value) => new Promise((resolve) => resolve(value));

/**
 * Important note: section is not considered as a required and standalone part.
 * Path to the global (i.e. window) property could have any nesting level.
 *
 * @param {string} path
 * @param {*} defaults
 * @param {boolean} [asPromise=true]
 * @returns {*|Promise<unknown>}
 */
export const resolveGlobalOverride = (path, defaults, asPromise) => {
    let value = _.get(window, ['_csBootstrap', 'sections'].concat(path.split('.')), defaults);
    const pathDirect = path.split('.');
    // 1. Check bootstrap values.
    const pathAlias = _.get(window, ['_csBootstrap', 'aliases', path], defaults);
    if (value !== defaults) {
        return asPromise ? getResolvedPromise(value) : value;
    } else if (pathAlias) {
    // 2. Fallback to alias.
        value = _.get(window, pathAlias.split('.'), defaults);
        if (value !== defaults) {
            return asPromise ? getResolvedPromise(value) : value;
        }
    }
    // 3. Try to resolve value by direct access of variable.
    value = _.get(window, pathDirect);
    if (defaults !== _.get(window, pathDirect, defaults)) {
        return asPromise ? getResolvedPromise(value) : value;
    }
    return defaults;
};

export const resolveCookieOverride = (path, defaults, asPromise) => {
    let value = getCookieByName(path);
    if (value && value !== defaults) {
        return asPromise ? getResolvedPromise(value) : value;
    }
    const pathAlias = _.get(window, ['_csBootstrap', 'aliases', path], defaults);
    value = getCookieByName(pathAlias);
    if (value && value !== defaults) {
        return asPromise ? getResolvedPromise(value) : value;
    }
    const pathDirect = path.split('.').pop();
    value = getCookieByName(pathDirect);
    if (value && value !== defaults) {
        return value;
    }
    return defaults;
};

export const resolveOverride = (section, path, defaults, asPromise) => {
    asPromise = asPromise === undefined ? true : !!asPromise;
    let concatPath = path ? `${section}.${path}` : `${section}`;
    let value = resolveGlobalOverride(concatPath, defaults, asPromise);
    if (value !== defaults) {
        return value;
    }
    value = resolveCookieOverride(`${section}.${path}`, defaults, asPromise);
    if (value !== defaults) {
        return value;
    }
    return defaults;
};

export const observeCookieOverride = (path, callback) => {
    const observablePaths = [path];
    const pathAlias = _.get(window, ['_csBootstrap', 'aliases', path], undefined);
    if (pathAlias && observablePaths.indexOf(pathAlias) === -1) {
        observablePaths.push(pathAlias);
    }
    const pathDirect = path.split('.').pop();
    if (pathDirect && observablePaths.indexOf(pathDirect) === -1) {
        observablePaths.push(pathDirect);
    }

    observablePaths.forEach((cookieName) => {
        observeCookie(cookieName, callback);
    });
};

export const observeOverride = (section, path, callback) => {
    if (callback && typeof callback === 'object' && typeof callback.next === 'function') {
        callback = callback.next;
    }
    observeCookieOverride(`${section}.${path}`, callback);
};

export default {
    resolveGlobalOverride,
    resolveCookieOverride,
    resolveOverride,
    observeCookieOverride,
    observeOverride
};
