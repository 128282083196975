'use strict';

import { resolveTs } from './timestampResolver';

/**
 * Verifies either the current section needs to be re-fetched from the backend.
 *
 * @param {Object} sectionRecord
 * @param {Object} metaData
 * @return {boolean}
 */
export function isSectionInvalidationRequired(sectionRecord, metaData) {
    const tsUtc = resolveTs(sectionRecord);

    // If currently stored version of data is out-of-date.
    if (sectionRecord.ts + sectionRecord.ttl <= tsUtc) {
        sectionRecord.invalidationNeeded = true;
    }
    // If cookies-based version is newer than currently stored version.
    // sectionRecord.uf - stands to "update flag" - means trigger invalidation regardless of the current version.
    if (!metaData.v || sectionRecord.version > metaData.v || !!sectionRecord.uf) {
        sectionRecord.invalidationNeeded = true;
    }
    return !!sectionRecord.invalidationNeeded;
}
