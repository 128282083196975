'use strict';

/**
 * updates the totals summary
 * @param {Array} totals - the totals data
 */
// SFRA incompatible function signature: SFRA uses (totals) instead of (order).
function updateTotals(order) { // #SFRA6
    // Script had customization to use "order" instead of "totals"
    // @see a76007c3823f0fafe961612710f0df1ddc924f2a
    // reverting to original version

    var totals = order.totals ? order.totals : order;
    $('.shipping-total-cost').text(totals.totalShippingCost);
    $('.tax-total').text(totals.totalTax);
    $('.sub-total').text(totals.subTotalBasePriceBased); // Original version: subTotalBasePriceBased
    $('.grand-total-sum').text(totals.grandTotal !== '-' && totals.grandTotal || totals.subTotal);

    if (totals.invoiceFee && !!totals.invoiceFee.value) {
        $('[data-totals-invoice-fee]').removeClass('visually-hidden');
        $('[data-totals-invoice-cost]').text(totals.invoiceFee.formatted);
    } else {
        $('[data-totals-invoice-fee]').addClass('visually-hidden');
        $('[data-totals-invoice-cost]').text('');
    }

    if (totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').show();
        $('.shipping-discount-total').text('- ' +
            totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').hide();
    }

    $('[data-discount-line-item]').remove();

    if (totals.discounts && totals.discounts.length > 0) {
        $(totals.discountsHtml).insertAfter('[data-subtotal-lineitem]');
    }

    if (totals.productLevelDiscount.value > 0) {
        $('.coupons-and-promos .end-lines .product-discount-total').empty().append('- ' + totals.productLevelDiscount.formatted);
    } else {
        $('.coupons-and-promos .end-lines .product-discount-total').empty();
    }

    if (totals.orderLevelDiscountTotal.value > 0) {
        $('.coupons-and-promos .end-lines .order-discount-total').empty().append('- ' + totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.coupons-and-promos .end-lines .order-discount-total').empty();
    }

    totals.discounts.forEach(function (discount) {
        $('.order-discount-' + discount.UUID2).empty().append(discount.discountedPrice);
        $('.product-discount-' + discount.UUID2).empty().append(discount.discountedPrice);
    });

    if (totals.giftCertificateHtml) {
        $('[data-giftcertificate-lineitem]').empty().html(totals.giftCertificateHtml);
    }
}

/**
 * updates the order product shipping summary for an order model
 * @param {Object} order - the order model
 */
function updateOrderProductSummaryInformation(order) {
    var $productSummary = $('<div />');
    order.shipping.forEach(function (shipping) {
        shipping.productLineItems.items.forEach(function (lineItem) {
            var pli = $('[data-product-line-item=' + lineItem.UUID + ']');
            $productSummary.append(pli);
        });

        var address = shipping.shippingAddress || {};
        var selectedMethod = shipping.selectedShippingMethod;

        var nameLine = address.firstName ? address.firstName + ' ' : '';
        if (address.lastName) nameLine += address.lastName;

        var address1Line = address.address1;
        var address2Line = address.address2;

        var phoneLine = address.phone;

        var shippingCost = selectedMethod ? selectedMethod.shippingCost : '';
        var methodNameLine = selectedMethod ? selectedMethod.displayName : '';
        var methodArrivalTime = selectedMethod && selectedMethod.estimatedArrivalTime
            ? '( ' + selectedMethod.estimatedArrivalTime + ' )'
            : '';

        var tmpl = $('#pli-shipping-summary-template').clone();

        if (shipping.productLineItems.items && shipping.productLineItems.items.length > 1) {
            $('h5 > span').text(' - ' + shipping.productLineItems.items.length + ' '
                + order.resources.items);
        } else {
            $('h5 > span').text('');
        }

        var stateRequiredAttr = $('#shippingState').attr('required');
        var isRequired = stateRequiredAttr !== undefined && stateRequiredAttr !== false;
        var stateExists = (shipping.shippingAddress && shipping.shippingAddress.stateCode)
            ? shipping.shippingAddress.stateCode
            : false;
        var stateBoolean = false;
        if ((isRequired && stateExists) || (!isRequired)) {
            stateBoolean = true;
        }

        var shippingForm = $('.multi-shipping input[name="shipmentUUID"][value="' + shipping.UUID + '"]').parent();

        if (shipping.shippingAddress
            && shipping.shippingAddress.firstName
            && shipping.shippingAddress.address1
            && shipping.shippingAddress.city
            && stateBoolean
            && shipping.shippingAddress.countryCode
            && (shipping.shippingAddress.phone || shipping.productLineItems.items[0].fromStoreId)) {
            $('.ship-to-name', tmpl).text(nameLine);
            $('.ship-to-address1', tmpl).text(address1Line);
            $('.ship-to-address2', tmpl).text(address2Line);
            $('.ship-to-city', tmpl).text(address.city);
            if (address.stateCode) {
                $('.ship-to-st', tmpl).text(address.stateCode);
            }
            $('.ship-to-zip', tmpl).text(address.postalCode);
            $('.ship-to-phone', tmpl).text(phoneLine);

            if (!address2Line) {
                $('.ship-to-address2', tmpl).hide();
            }

            if (!phoneLine) {
                $('.ship-to-phone', tmpl).hide();
            }

            shippingForm.find('.ship-to-message').text('');
        } else {
            shippingForm.find('.ship-to-message').text(order.resources.addressIncomplete);
        }

        if (shipping.isGift) {
            $('.gift-message-summary', tmpl).text(shipping.giftMessage);
        } else {
            $('.gift-summary', tmpl).addClass('d-none');
        }

        // checking h5 title shipping to or pickup
        var $shippingAddressLabel = $('.shipping-header-text', tmpl);
        $('body').trigger('shipping:updateAddressLabelText',
            { selectedShippingMethod: selectedMethod, resources: order.resources, shippingAddressLabel: $shippingAddressLabel });

        if (shipping.selectedShippingMethod) {
            $('.display-name', tmpl).text(methodNameLine);
            $('.arrival-time', tmpl).text(methodArrivalTime);
            $('.price', tmpl).text(shippingCost);
        }

        var $shippingSummary = $('<div class="multi-shipping" data-shipment-summary="'
            + shipping.UUID + '" />');
        $shippingSummary.html(tmpl.html());
        $productSummary.append($shippingSummary);
    });

    $('.product-summary-block').html($productSummary.html());

    // Also update the line item prices, as they might have been altered
    $('.grand-total-price').text(order.totals.subTotal);
    order.items.items.forEach(function (item) {
        if (item.priceTotal && item.priceTotal.renderedPrice) {
            $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
        }
    });
}

module.exports = {
    updateTotals: updateTotals,
    updateOrderProductSummaryInformation: updateOrderProductSummaryInformation
};
