function isBrowserSupportingFetch() {
    if (!('fetch' in window)) {
        return false;
    }

    try {
        new Headers();
        new Request('http://www.example.com');
        new Response();
        return true;
    } catch (e) {
        return false;
    }
}

function makeRequest(url, body) {
    const isRealNavigator = Object.prototype.toString.call(global && global.navigator) === '[object Navigator]';
    const hasSendBeacon = isRealNavigator && typeof global.navigator.sendBeacon === 'function';

    if (hasSendBeacon) {
    // Prevent illegal invocations - https://xgwang.me/posts/you-may-not-know-beacon/#it-may-throw-error%2C-be-sure-to-catch
        const sendBeaconFn = global.navigator.sendBeacon.bind(global.navigator);
        sendBeaconFn(url, body);
    } else if (isBrowserSupportingFetch()) {
        fetch(url, {
            body: body,
            method: 'POST',
            credentials: 'omit',
            keepalive: true
        }).then(null, function (error) {
            console.error(error);
        });
    }
}

export default {
    isBrowserSupportingFetch: isBrowserSupportingFetch,
    makeRequest: makeRequest
};
