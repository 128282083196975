import EmblaCarousel from 'shared/js/embla';

export default (widget) => {
    return {
        embla: null,
        next: null,
        prev: null,

        init() {
            let self = this;
            this.embla = EmblaCarousel(
                // TODO Postpone until selector appears.
                // Re-create on update.
                widget.$refs.viewport || document.querySelector('.klarna-upsell-embla__viewport'),
                {
                    dragFree: true,
                    slidesToScroll: 1, // Depends on desktop / mobile version
                    containScroll: 'trimSnaps',
                    align: 'start',
                    loop: false,
                    inViewThreshold: 0.8,
                    breakpoints: {
                        '(min-width: 1024px)': {
                            dragFree: true,
                            slidesToScroll: 3,
                            containScroll: 'keepSnaps',
                            align: 'center',
                            loop: false,
                        },
                    },
                }
            );
            this.embla.on('select', () => {
                self.next = self.embla.canScrollNext();
                self.prev = self.embla.canScrollPrev();
            });
            this.embla.on('init', () => {
                self.next = self.embla.canScrollNext();
                self.prev = self.embla.canScrollPrev();
            });
            var dots = document.getElementsByClassName('embla__dot');
            this.embla.on('scroll', () => {
                var selectedIndex = this.embla.slidesInView()[0] - 1;
                for (var i = 0; i < dots.length; i++) {
                    if (i == selectedIndex) {
                        dots[i].classList.add('embla__dot--selected');
                    } else {
                        dots[i].classList.remove('embla__dot--selected');
                    }
                }
            });
        }
    };
};
