'use strict';

import MessageMgr from '../messages';
import messagesConst from '../messagesConst';

const ERROR_MESSAGE = 'Unhandled exception, please try again.';
let handle = function (message) {
    if (message.type !== messagesConst.LEVEL_ERROR
        || message.source !== 'ajax') {
        return;
    }

    const jqxhr = message.context.jqxhr;
    const responseType = message.context.responseType;
    const response = message.context.response;
    const toastNotifyUnhandledError = $('.data-holder').data('toast-notify-unhandled-error');

    if (toastNotifyUnhandledError === 'true' && (responseType === 'error' || (jqxhr.status > 399 && jqxhr.status < 600))) {
        MessageMgr.error(ERROR_MESSAGE);
    }

    if (response instanceof Object && response.error) {
        const toastNotifyAnyHandledError = $('.data-holder').data('toast-notify-any-handled-error');

        if (toastNotifyAnyHandledError === 'true' || response.errorLocalizedFlag) {
            const errorMessage = response.errorMessage || ERROR_MESSAGE;
            MessageMgr.error(errorMessage);
        }
    }
};

export default handle;
