/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    if (typeof data === 'object' && data.totals && data.items) {
        $('.shipping-total-cost').empty().append(data.totals.totalShippingCost);
        $('.tax-total').empty().append(data.totals.totalTax);
        $('.grand-total-sum').empty().append(data.totals.grandTotal);
        $('.sub-total').empty().append(data.totals.subTotal);

        if (data.displayBonusProductMessage) {
            $('.msg-bonus-product').show();
        } else {
            $('.msg-bonus-product').hide();
        }

        $('[data-discount-line-item]').remove();

        if (data.totals.discounts && data.totals.discounts.length > 0) {
            $(data.totals.discountsHtml).insertAfter('[data-subtotal-lineitem]');
        }

        if (data.totals.productLevelDiscount.value > 0) {
            $('.coupons-and-promos .end-lines .product-discount-total').empty().append('- ' + data.totals.productLevelDiscount.formatted);
        } else {
            $('.coupons-and-promos .end-lines .product-discount-total').empty();
        }
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos .end-lines .order-discount-total').empty().append('- ' + data.totals.orderLevelDiscountTotal.formatted);
        } else {
            $('.coupons-and-promos .end-lines .order-discount-total').empty();
        }

        if (data.totals.shippingLevelDiscountTotal.value > 0) {
            $('.shipping-discount').removeClass('visually-hidden');
            $('.shipping-discount-total').empty().append('- ' +
                data.totals.shippingLevelDiscountTotal.formatted);
        } else {
            $('.shipping-discount').addClass('visually-hidden');
        }

        data.totals.discounts.forEach(function (discount) {
            $('.order-discount-' + discount.UUID2).empty().append(discount.discountedPrice);
            $('.product-discount-' + discount.UUID2).empty().append(discount.discountedPrice);
        });

        const bonusProductHtml = data.items.bonusProductHtml || '';
        // Remove all bonus product line items.
        $('bonus-tile-product').remove();
        data.items.items.forEach(function (item) {
            if (!item.isGift) {
                var append = true;
                var priceSales = $('.line-item-price-' + item.UUID + ' .price-sales');
                if (priceSales && item.price.list && append) {
                    priceSales.empty();
                    priceSales.parent().append('<div class="price-adjusted"></div><div class="price-non-adjusted"></div>');
                }
                var adjustedElement = $('.line-item-price-' + item.UUID + ' .price-adjusted');
                var nonAdjustedElement = $('.line-item-price-' + item.UUID + ' .price-non-adjusted');
                $('.item-' + item.UUID).empty().append(item.renderedPromotions);
                if (adjustedElement.length > 1) {
                    adjustedElement[1].remove();
                    nonAdjustedElement[1].remove();
                } else {
                    adjustedElement.empty().append(item.price.sales.formatted);
                }
                adjustedElement.css('color', '#cd0000');
                nonAdjustedElement.empty();
                if (item.price.list) {
                    nonAdjustedElement.append(item.price.list.formatted);
                } else {
                    adjustedElement.css('color', '#000000');
                }
                $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
                $('.item-included-products-' + item.UUID).empty().append(item.custom.setProducts);

                var bonusLineItem = $('.product-summary-block .bonus-tile-product');

                if (bonusLineItem.length) {
                    bonusLineItem.remove();
                }

                if (item.isBonusProductLineItem && bonusProductHtml) {
                    $('.product-summary-block .product-line-item').last().after(bonusProductHtml);
                }
            }
        });
    }
}

module.exports = {
    updateCartTotals: updateCartTotals,
};
