'use strict';

import MessagesMgr from './components/messages';
import MessagesBus from './components/messagesBus';
import MessagesBusJqueryAjax from './components/messagesBus/messageBusJqueryAjax';
import MessagesBusCookieMessage from './components/messagesBus/messageBusCookieMessage';

import ajaxErrorListener from './components/messagesProcessor/ajaxErrorToastr';
import cookieBasedMToastr from './components/messagesProcessor/genericToastr';

window.messagesBus = new MessagesBus([
    new MessagesBusJqueryAjax(),
    new MessagesBusCookieMessage()
]);

// Register ajax requests handler.
// The next is deprecated, left for git tracking history util it could be smoothly removed.
// require('./components/messagesAjaxErrorTrigger');
window.messagesBus.register('ajax-error-show-toastr', ajaxErrorListener);
window.messagesBus.register('cookie-based-show-toastr', cookieBasedMToastr);

export default MessagesMgr;
