import _ from 'shared/js/underscore';

const mixin = (widgetInstance) => {
    widgetInstance.contentMediator.export.version
        = widgetInstance.contentMediator.version;
    let sliderInstance = widgetInstance.slider;

    return ({
        get activeScene() {
            return widgetInstance.contentMediator.activeScene;
        },
        header: {
            'x-html'() {
                widgetInstance.contentMediator.callbacks = [];
                widgetInstance.timerInjectorInstance.revert();
                const html = widgetInstance.contentMediator.content
                    ? widgetInstance.contentMediator.content.header
                        .replace(/\[\[timer]]/, '<span id="klarna-upsell__timer-wrapper"></span>')
                    : '';
                widgetInstance.contentMediator.callbacks.push(() => {
                    // Can be checked for replacement with $nextTick.
                    widgetInstance.timerInjectorInstance.apply();
                    sliderInstance.init();
                    widgetInstance.$scroll(0, { behavior: 'auto' }); // Use AlpineJs plugin.
                });
                _.debounce(() => {
                    widgetInstance.contentMediator.callbacks.forEach(callback => callback());
                }, 10)();
                return html;
            }
        },
        footer: {
            'x-html'() {
                return widgetInstance.contentMediator.content
                    ? widgetInstance.contentMediator.content.footer
                    : '';
            }
        },
        confirmation: {
            'x-html'() {
                return widgetInstance.contentMediator.content
                    ? widgetInstance.contentMediator.content.confirmation
                    : '';
            }
        },
        timeout: {
            'x-html'() {
                return widgetInstance.contentMediator.content
                    ? widgetInstance.contentMediator.content.timeout
                    : '';
            }
        },
        exception: {
            'x-html'() {
                return widgetInstance.contentMediator.content
                    ? widgetInstance.contentMediator.content.exception
                    : '';
            }
        },
        products: ({
            get items() {
                return widgetInstance.contentMediator.content
                    && _.isArray(widgetInstance.contentMediator.content.products.items)
                    ? widgetInstance.contentMediator.content.products.items
                    : [];
            },
            get slider() {
                return widgetInstance.slider;
            }
        })
    });
};

export default (widgetInstance) => ({
    init() {
    // Execute action on timeout.
        widgetInstance.componentTimer.addCallback(() => {
            if (widgetInstance.contentMediator.activeScene !== 'confirmation') {
                widgetInstance.contentMediator.activeScene = 'timeout';
            }
        });

        // Register main content fragment renderer.
        widgetInstance.contentMediator.export['x-data'] = _.wrap(
            widgetInstance.contentMediator.export['x-data'],
            mixin.bind(this, widgetInstance)
        );
    },
});
