/* jshint esversion: 6 */
/* eslint-disable no-unused-vars */

'use strict';

import Client from './client.js';
import Alpine from 'shared/js/alpinejs';
import MessagesMgr from 'shared/js/messagesMgr';
import { getControllerUrl } from 'shared/js/url';
import _ from 'shared/js/underscore';
import updateCart from '../cart/updateCartWithPromo';

const FAILED_TO_RETRIEVE_DATA_MESSAGE = 'Failed to retrieve the promotions information. Please, try again later.';
const WAIT_PERIOD = 1500;

let DataProvider = new Client(
    Alpine.reactive({ items: [] }),
    getControllerUrl('Account-PromotionListing'),
    getControllerUrl('Account-PromotionSwitch')
);

const sync = _.throttle(_.debounce(function () {
    DataProvider.persist()
        .then(response => {
            if (_.isObject(response.data)
                && _.isObject(response.data.totals)
                && !_.isEmpty(response.data.totals)
                && _.isObject(response.data.totals.totals)
            ) {
                updateCart.updateCartTotals(response.data.totals);
                $('body').trigger('cart:update');
            }
        })
        .catch(reason => {
            MessagesMgr.error(FAILED_TO_RETRIEVE_DATA_MESSAGE);
        });
}, WAIT_PERIOD), WAIT_PERIOD);

/**
 * The AlpineJS component.
 * @return {{init: init, items: (function(): [])}}
 */
export default (type) => ({
    /**
     * @type {String} Currently to distinguish "checkout" and "account".
     */
    widgetType: type,

    /**
     * Components initialization.
     */
    init: () => {
        DataProvider.retrieve().catch(reason => {
            MessagesMgr.error(FAILED_TO_RETRIEVE_DATA_MESSAGE);
        });
    },

    /**
     * Returns the registered promotions.
     *
     * @return {Object[]}
     */
    items: () => {
        return type === ('checkout' || 'account')
            ? _.filter(DataProvider.data.items, (item) => {
                return !item.redeemed || (item.countByStatus.unused || 0) > 0;
            })
            : DataProvider.data.items;
    },

    /**
     * Makes callout to update the activation status of promotions to backend.
     *
     * @return {void}
     */
    update: () => {
        sync.cancel();
        sync();
    },

    /**
     * Indicates either the widget is performing a callout.
     *
     * @type {Boolean}
     */
    isLoading: () => {
        return DataProvider.isLoading;
    },

    /**
     * Return true/false depends on number of promotions
     *
     * @type {Boolean}
     */
    isEmpty: () => {
        return DataProvider.data.isEmpty;
    },

    /**
     * Return URL for fallback image when there are no promotions assigned for customer
     *
     * @return {String}
     */
    noPromotionsImgUrl() {
        return DataProvider.data.noPromotionsImgUrl;
    },

    tile: {
        ['x-bind:class']() {
            Alpine.tracker(DataProvider.data.items);
            return {
                'grid-item--redeemed': this.item.redeemed,
                'grid-item--applied': this.item.applied && this.item.active,
                'grid-item--active-not-applied': !this.item.applied && this.item.active
            };
        }
    },
    promotionContainer: {
        ['x-bind:class']() {
            return {
                'checkout-promotion-empty': DataProvider.data.items.length < 1,
            };
        }
    },

    controllerReload: {
        ['@click']() { // NOSONAR
            DataProvider.retrieve(true).catch(reason => {
                MessagesMgr.error(FAILED_TO_RETRIEVE_DATA_MESSAGE);
            });
        }
    }
});
