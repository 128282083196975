'use strict';

class Provider {
    /**
     * @param {*} container
     * @param {*} [meta=undefined]
     * @return {boolean}
     */
    isApplicable(container, meta) { // eslint-disable-line no-unused-vars, class-methods-use-this
        return false; // Need to be implemented in children classes.
    }

    /**
     * @param {*} container
     * @param {*} [meta=undefined]
     * @return {Event[]}
     */
    generate(container, meta) { // eslint-disable-line no-unused-vars, class-methods-use-this
        return []; // Need to be implemented in children classes.
    }
}

export default Provider;
