'use strict';

export default {
    LEVEL_ERROR: 'error',
    LEVEL_WARNING: 'warn',
    LEVEL_INFORMATION: 'info',
    LEVEL_SUCCESS: 'success',
    REQUEST_ERROR_5xx: 'ERROR_5xx',
    ERROR_GENERIC: 'ERROR_GENERIC',
    REQUEST_SUCCESS: 'SUCCESS_2xx'
};
