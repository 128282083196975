'use strict';

import _ from 'shared/js/underscore';

export default (content, map) => {
    // Parse as HTML since HTML fragments cannot be wrapped into CDATA section because of SFCC comments.
    const doc = new window.DOMParser().parseFromString(content.replace(/(<!--.*?-->)|(<!--[\S\s]+?-->)|(<!--[\S\s]*?$)/g, ''), 'text/html');
    let result = {};
    _.each(map, function (processor) {
        if (processor.hasOwnProperty('byTagName')) {
            const elements = doc.getElementsByTagName(processor.byTagName);
            _.each(elements, (element) => {
                processor.callback(element, result);
            });
        }
    });
    return result;
};
