'use strict';

import _ from 'shared/js/underscore';
import { getTableName } from './dbNameResolver';

/**
 * Iterates over all metaData fields and extracts those which relate to the specific table ID.
 *
 * @param {String} id
 * @param {Object} metaData
 * @return {Object}
 */
export function extractMetaDataFields(id, metaData) {
    return _.reduce(metaData, (memo, value, key) => {
        const prefix = `${getTableName(id)}_`;
        if (key.indexOf(prefix) === 0) {
            memo[key.substring(prefix.length)] = value;
        }
        return memo;
    }, {});
}

