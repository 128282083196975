'use strict';

const DB_NAME_PREFIX = 'h4';
import { getSiteName } from 'shared/js/url';

/**
 * Returns DB name that includes site name & locale.
 * @return {string}
 */
export function getDbName() {
    return `${DB_NAME_PREFIX}-${window.currentLocale}-${getSiteName()}`;
}

/**
 * Returns name for the table within namespace/db.
 *
 * @param {string} name
 * @return {string}
 */
export function getTableName(name) {
    return `${name}-tbl`;
}
