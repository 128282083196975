'use strict';

/**
 * Resolves the current timestamp.
 *
 * @return {number}
 */
export function resolveTs() {
    const ts = new Date();
    return Date.UTC(
        ts.getUTCFullYear(),
        ts.getUTCMonth(),
        ts.getUTCDate(),
        ts.getUTCHours(),
        ts.getUTCMinutes(),
        ts.getUTCSeconds(),
        ts.getUTCMilliseconds()
    );
}

/**
 * Resolves the current timestamp-based version.
 *
 * @param {{rv: [number]}} section
 * @return {number}
 */
export function resolveVersion(section) {
    let tsUtc = resolveTs(section);

    if (section.rv && typeof section.rv === 'number') {
        tsUtc -= (tsUtc % section.rv);
    }

    return tsUtc;
}
