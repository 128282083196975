/* jshint esversion: 6 */

'use strict';

import Alpine from './alpinejs/lib';
import Ext from './alpinejs/lib-ext';
import parent from './alpinejs/parent';
import focus from './alpinejs/focus';
import ui from './alpinejs/ui';
import persist from './alpinejs/persist';

// Usage of npm packages instead will lead to a need to support on ES2017 builds, but that is not possible
// because of still preserving support of older version of browsers. Due to that the latest (v3.8+) alpinejs version
// cannot be used "as is". Currently stored lib.js has manually fixed version 3.11.1 which is compatible with ES2015.
// import Alpine from 'alpinejs'
// import Ext from './alpinejs/lib-ext'
// import parent from './alpinejs/parent'
// import focus from '@alpinejs/focus'
// import ui from '@alpinejs/ui'
// import collapse from '@alpinejs/collapse'
// import intersect from '@alpinejs/intersect'
// import mask from '@alpinejs/mask'

// Apply extending/plugins to the Alpine.
Ext(Alpine);
parent(Alpine);
focus(Alpine);
ui(Alpine);
persist(Alpine);
// collapse(Alpine)
// intersect(Alpine)
// mask(Alpine)

// On document ready:
// 1. collect all registered components
// 2. initialize Alpine
document.addEventListener('custom:shared-components-loaded', () => {
    const collectComponentsEvent = new CustomEvent(
        'alpine:collect-components',
        {
            Alpine: Alpine
        }
    );
    document.dispatchEvent(collectComponentsEvent);

    Alpine.start();
});

window.Alpine = Alpine;

export default Alpine;
